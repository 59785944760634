import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../services";

function PrivateRoute({ children }) {
  const { checkAuthStatus } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function checkLoginStatus() {
      const status = await checkAuthStatus();
      setLoggedIn(status);
      setIsLoading(false);
    }
    checkLoginStatus();
  }, []);

  if (isLoading) {
    // Optionally show a loader while checking
    return <div>Loading...</div>;
  }

  return loggedIn ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
