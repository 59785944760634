import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CandidateDetails,
  CandidatesTable,
  EmployeeDetails,
  EmployeesTable,
  OfferLetter,
  OnboardingRound1,
  OnboardingRound2,
  OnboardingRound3,
  PersonalDataEntry,
} from "../../components/admin";
import { useApiService } from "../../services";
import styles from "./EmployeeCandidate.module.css";

const staticCandidates = [
  {
    id: 1,
    application_date: "2023-09-01",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "Rejected",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 2,
    application_date: "2023-08-21",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "On Hold",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 3,
    application_date: "2024-01-01",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "In Process",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 4,
    application_date: "2024-02-14",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "Recruited",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 5,
    application_date: "2023-12-06",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "In Process",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 6,
    application_date: "2023-08-01",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "Rejected",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
  {
    id: 7,
    application_date: "2023-08-01",
    candidate_name: "John Doe",
    candidate_contact: "1234567890",
    candidate_email: "W6LsM@example.com",
    status: "On Hold",
    position_name: "Software Engineer",
    department_name: "ABC Company",
  },
];

const staticEmployees = [
  {
    employee_id: 1,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "1 year 2 months",
  },
  {
    employee_id: 2,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "2 year 3 months",
  },
  {
    employee_id: 3,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "3 year 4 months",
  },
  {
    employee_id: 4,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "4 year 5 months",
  },
  {
    employee_id: 5,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "5 year 6 months",
  },
  {
    employee_id: 6,
    employee_name: "John Doe",
    employee_email: "W6LsM@example.com",
    employee_contact: "1234567890",
    position_name: "Software Engineer",
    department_name: "ABC Company",
    employment_period: "6 year 7 months",
  },
];

const staticCandidateData = {
  id: 1,
  application_date: "2023-09-01",
  candidate_name: "John Doe",
  candidate_contact: "1234567890",
  candidate_email: "W6LsM@example.com",
  status: "In Process",
  position_name: "Software Engineer",
  age: 25,
  gender: "Male",
  candidate_linkedin: "https://www.linkedin.com/in/johndoe",
  resume_url: "https://example.com/resume.pdf",
  current_salary: 5.5, // in LPA
  expected_salary: 6.5, // in LPA
  interview_date: "2023-10-01",
  interview_time: "10:00 AM",
};

const staticEmployeeData = {
  id: 1,
  employee_name: "John Doe",
  employee_email: "W6LsM@example.com",
  employee_contact: "1234567890",
  position_name: "Software Engineer",
  department_name: "ABC Company",
  employee_age: 25,
  employee_gender: "Male",
  employee_linkedin: "https://www.linkedin.com/in/johndoe",
  current_salary: 5.5, // in LPA
  employment_period: "1 year 2 months",
  resume_url: "https://example.com/resume.pdf",
};

const EmployeesCandidatesPage = () => {
  const { get } = useApiService();
  const [candidates, setCandidates] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("page"));

  useEffect(() => {
    const currentPage = searchParams.get("page");
    if (!currentPage) {
      setSearchParams({ page: "candidates" });
      setSelectedTab("candidates");
    }
  }, []);

  useEffect(() => {
    const getCandidates = async () => {
      try {
        const response = await get("/api/admin/candidates");
        if (response.success && response.data) {
          console.log(response.data);
          setCandidates(response.data);
        }
        throw new Error("Failed to fetch candidates");
      } catch (error) {
        console.error(error);
      }
    };
    const getEmployees = async () => {
      try {
        const response = await get("/api/admin/employees");
        if (response.success && response.data) {
          console.log(response.data);
          setEmployees(response.data);
        }
        throw new Error("Failed to fetch employees");
      } catch (error) {
        console.error(error);
      }
    };
    // if (selectedTab === "candidates") {
    //   getCandidates();
    // } else if (selectedTab === "employees") {
    //   getEmployees();
    // }
  }, []);

  const [candidateData, setCandidateData] = useState(staticCandidateData);
  const [selectedCandidateId, setSelectedCandidateId] = useState(
    searchParams.get("candidate_id")
  );
  useEffect(() => {
    const getCandidateDetails = async (id) => {
      try {
        const response = await get(`/api/admin/candidate/${id}`);
        if (response.success && response.data) {
          setCandidateData(response.data);
        }
        throw new Error("Failed to fetch candidate details");
      } catch (error) {
        toast.error("Failed to fetch candidate details. Please try again.");
      }
    };
    if (
      selectedTab === "candidates" &&
      selectedCandidateId &&
      Number(selectedCandidateId)
    ) {
      // getCandidateDetails(Number(selectedCandidate));
    }
  }, [selectedCandidateId]);

  const [employeeData, setEmployeeData] = useState(staticEmployeeData);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    searchParams.get("employee_id")
  );
  useEffect(() => {
    const getEmployeeDetails = async (id) => {
      try {
        const response = await get(`/api/admin/employee/${id}`);
        if (response.success && response.data) {
          setEmployeeData(response.data);
        }
        throw new Error("Failed to fetch employee details");
      } catch (error) {
        toast.error("Failed to fetch employee details. Please try again.");
      }
    };
    if (
      selectedTab === "employees" &&
      selectedEmployeeId &&
      Number(selectedEmployeeId)
    ) {
      // getEmployeeDetails(Number(selectedCandidate));
    }
  }, [selectedEmployeeId]);

  console.log({ candidateData });
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setSearchParams({ page: tab });
  };

  return (
    <>
      <section className="btn_group btn_group--end">
        <button type="button" className="btn cta_btn">
          + Hiring Request
        </button>
      </section>
      <section className={styles.toggle}>
        <button
          className={`${styles.toggle_item} ${
            selectedTab === "candidates" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("candidates")}
        >
          Candidates
        </button>
        <button
          className={`${styles.toggle_item} ${
            selectedTab === "employees" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("employees")}
        >
          Employees
        </button>
      </section>
      {selectedTab === "candidates" ? (
        <CandidatesTable
          candidatesData={candidates ? candidates : staticCandidates}
          setSelectedCandidate={setSelectedCandidateId}
        />
      ) : selectedTab === "employees" ? (
        <EmployeesTable
          employeesData={employees ? employees : staticEmployees}
          setSelectedEmployee={setSelectedEmployeeId}
        />
      ) : (
        ""
      )}

      <div className="divider" />

      {selectedTab === "candidates" && selectedCandidateId && (
        <>
          <CandidateDetails
            candidateData={candidateData}
            id={selectedCandidateId}
          />
          {/* <ProgressBar /> */}
          <OnboardingRound1 candidateData={candidateData} />
          <OnboardingRound2 candidateData={candidateData} />
          <OnboardingRound3 candidateData={candidateData} />
          <OfferLetter />
          <PersonalDataEntry />
        </>
      )}

      {selectedTab === "employees" && selectedEmployeeId && (
        <EmployeeDetails employeeData={employeeData} />
      )}
    </>
  );
};

export default EmployeesCandidatesPage;
