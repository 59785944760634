import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AssignedJobs.module.css";

const AssignedJobs = ({ jobData }) => {
  // const [assignedJobs, setAssignedJobs] = useState([]);

  // useEffect(() => {
  //   const fetchAssignedJobs = () => {}
  //   fetchAssignedJobs()
  // }, [])

  // if (assignedJobs.length === 0) return<></>;

  const workData = {
    ...jobData,
    work_title: "Work Title",
    department: "Department",
    employee: "Employee",
    hours: "3",
    minutes: "30",
    priority: "Urgent",
    schedule: "Week 1, Feb '25",
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
    total_rate: "3000",
  };

  const [newDocumentName, setNewDocumentName] = useState("");

  const handleDocumentUpload = () => {};

  return (
    <section className={styles.wrapper}>
      <button type="button" className={`btn ghost_btn ${styles.add_btn}`}>
        + Add Document
      </button>
      <section className={styles.details_wrapper}>
        <div className={styles.row}>
          <div className="label">Job Code</div>
          <div className={styles.value}>
            {workData.job_number ?? `# ${workData.id}`}
          </div>
        </div>
        <div className={styles.row}>
          <div className="label">Job Name</div>
          <div className={styles.value}>
            {workData.job_name ?? `Job ${workData.id}`}
          </div>
        </div>
        <div className={styles.row}>
          <div className="label">Work Title</div>
          <div className={styles.value}>{workData.work_title}</div>
        </div>
        <div className={styles.row}>
          <div className="label">Department</div>
          <div className={styles.value}>{workData.department}</div>
        </div>
        <div className={styles.row}>
          <div className="label">Employee Assigned</div>
          <div className={styles.value}>{workData.employee}</div>
        </div>
        <div className={styles.row}>
          <div className="label">Work Timeline</div>
          <div className={styles.value}>
            {workData.hours}:{workData.minutes}
          </div>
        </div>
        <div className={styles.row}>
          <div className="label">Work Priority</div>
          <div className={styles.value}>{workData.priority}</div>
        </div>
        <div className={styles.row}>
          <div className="label">Schedule</div>
          <div className={styles.value}>{workData.schedule}</div>
        </div>
        <div className={styles.full_row}>
          <div className="label">CAD File</div>
          <div className={styles.upload_group}>
            <Link to="#" className="ghost_btn">
              view CAD file
            </Link>
            <button className={styles.upload_btn}>Reupload</button>
          </div>
        </div>
        <div className={styles.full_row}>
          <span className="label">Upload Documents</span>
          <div className={`${styles.input_group_full} ${styles.documents}`}>
            <input
              type="text"
              name="document_name"
              onChange={(e) => setNewDocumentName(e.target.value)}
              value={newDocumentName}
              placeholder="Enter document name"
              className={`input ${styles.input}`}
            />
            <button
              type="button"
              className={styles.upload_btn}
              onClick={handleDocumentUpload}
            >
              Upload
              <input type="file" name="document" hidden />
            </button>
          </div>
        </div>
        <div className={styles.full_row}>
          <div className="label">Note/Comment</div>
          <div className={styles.note}>{workData.note}</div>
        </div>
        <section className={styles.total_rate}>
          Total Rate ₹{workData.total_rate}
        </section>
      </section>
    </section>
  );
};
export default AssignedJobs;
