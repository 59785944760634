import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./CandidatesTable.module.css";

const CandidatesTable = ({ candidatesData, setSelectedCandidate }) => {
  const [candidates, setCandidates] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  useEffect(() => {
    if (candidatesData && candidatesData.length > 0) {
      setCandidates(candidatesData);
    }
  }, [candidatesData]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedCandidates = [...candidates].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setCandidates(sortedCandidates);
  };

  const statusValues = {
    IN_PROCESS: "In Process",
    RECRUITED: "Recruited",
    REJECTED: "Rejected",
    ON_HOLD: "On Hold",
  };

  const getStatusClass = (status) => {
    switch (status) {
      case statusValues.IN_PROCESS:
        return "status-in-process";
      case statusValues.RECRUITED:
        return "status-recruited";
      case statusValues.REJECTED:
        return "status-rejected";
      case statusValues.ON_HOLD:
        return "status-on-hold";
      default:
        return "";
    }
  };

  const [searchParam, setSearchParam] = useSearchParams();
  const handleViewDetails = (candidateId) => {
    setSelectedCandidate(candidateId);
    setSearchParam({ page: "candidates", candidate_id: candidateId });
  };

  const candidateTableHeaders = [
    { id: "application_date", label: "Application Date" },
    { id: "candidate_name", label: "Candidate Name" },
    { id: "candidate_contact", label: "Candidate Contact" },
    { id: "candidate_email", label: "Email ID" },
    { id: "department_name", label: "Department" },
    { id: "position_name", label: "Position" },
    { id: "status", label: "Status" },
    { id: "action", label: "More Details" },
  ];

  return (
    <>
      <div className="data_table data_table_8">
        <div className="data_table_header">
          {candidateTableHeaders.map((header) => (
            <div key={header.id} className="data_table_header_cell">
              {header.label}
              {header.id !== "action" && (
                <button
                  type="button"
                  className="sort_btn"
                  onClick={() => handleSort(header.id)}
                >
                  <Icon
                    icon="ri:arrow-drop-down-line"
                    className={`sort_icon ${
                      sortConfig.key === header.id &&
                      sortConfig.direction === "asc"
                        ? "asc"
                        : "desc"
                    }`}
                  />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="data_table_body">
          {candidates.map((candidate) => (
            <div key={candidate.id} className="data_table_row">
              <div className="data_table_cell">
                {candidate.application_date}
              </div>
              <div className="data_table_cell">{candidate.candidate_name}</div>
              <div className="data_table_cell">
                {candidate.candidate_contact}
              </div>
              <div className="data_table_cell">{candidate.candidate_email}</div>
              <div className="data_table_cell">{candidate.department_name}</div>
              <div className="data_table_cell">{candidate.position_name}</div>
              <div
                className={`data_table_cell ${
                  styles[getStatusClass(candidate.status)]
                }`}
              >
                {candidate.status}
              </div>
              <div className="data_table_cell action">
                <button
                  type="button"
                  className="btn ghost_btn"
                  onClick={() => handleViewDetails(candidate.id)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CandidatesTable;
