import { Icon } from "@iconify/react";

function CandidateDetails({ candidateData }) {
  const {
    candidate_name,
    status,
    age,
    gender,
    candidate_contact,
    candidate_email,
    candidate_linkedin,
    resume_url,
    current_salary,
    expected_salary,
    interview_date,
    interview_time,
  } = candidateData;
  const getStatusClass = (status) => {
    return "status-" + status.split(" ").join("-").toLowerCase();
  };
  return (
    <section className="section">
      <div className="section_title_wrapper">
        <h3 className="section_title">{candidate_name} details</h3>
        <span className={getStatusClass(status)}>{status}</span>
        <button className="btn ghost_btn">
          Edit
          <Icon icon="carbon:edit" />
        </button>
      </div>
      <div className="section section--row">
        <div className="section_inner_container section--full">
          <div className="row">
            <div className="label label-gray">Candidate Name</div>
            <div className="value">{candidate_name}</div>
          </div>
          <div className="row">
            <div className="row section--full">
              <div className="label label-gray">Age</div>
              <div className="value">{age}</div>
            </div>
            <div className="row section--full">
              <div className="label label-gray">Gender</div>
              <div className="value">{gender}</div>
            </div>
          </div>
          <div className="row">
            <div className="label label-gray">Contact Number</div>
            <div className="value">{candidate_contact}</div>
          </div>
          <div className="row">
            <div className="label label-gray">Email ID</div>
            <div className="value">{candidate_email}</div>
          </div>
          <div className="row">
            <div className="label label-gray">Linked In</div>
            <div className="value">{candidate_linkedin}</div>
          </div>
        </div>
        <div className="vertical_divider"></div>
        <div className="section_inner_container section--full">
          <div className="row">
            <div className="label label-gray">Resume</div>
            <div className="ghost_btn">view</div>
          </div>
          <div className="row">
            <div className="label label-gray">Current Salary</div>
            <div className="value">₹ {current_salary} LPA</div>
          </div>
          <div className="row">
            <div className="label label-gray">Expected Salary</div>
            <div className="value">₹ {expected_salary} LPA</div>
          </div>
          <div className="row">
            <div className="label label-gray">Interview Date & Time</div>
            <div className="value">
              {interview_date}, {interview_time}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CandidateDetails;
