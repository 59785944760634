import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./PersonalDataEntry.module.css";

function PersonalDataEntry() {
  const [formData, setFormData] = useState({
    full_name: "",
    date_of_birth: "",
    age: "",
    gender: "",
    contact_number: "",
    email: "",
    residential_address: "",
    permanent_address: "",
    nationality: "",
    medical_condition: "",
  });
  const [personalDetailsVerified, setPersonalDetailsVerified] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const verifyPersonalDetails = () => {
    if (
      !formData.full_name ||
      !formData.full_name.length > 0 ||
      !formData.date_of_birth ||
      !formData.date_of_birth.length > 0 ||
      !formData.age ||
      !formData.age.length > 0 ||
      !formData.gender ||
      !formData.gender.length > 0 ||
      !formData.contact_number ||
      !formData.contact_number.length > 0 ||
      !formData.email ||
      !formData.email.length > 0 ||
      !formData.residential_address ||
      !formData.residential_address.length > 0 ||
      !formData.permanent_address ||
      !formData.permanent_address.length > 0 ||
      !formData.nationality ||
      !formData.nationality.length > 0
    ) {
      setPersonalDetailsVerified(false);
    }

    setPersonalDetailsVerified(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <section className="section">
      <h3 className="section_title section_title--uppercase">
        On-Boarding Process - Personal Data Entry to Our Board
      </h3>
      <div className="btn_group btn_group--end btn_group--dense">
        <button type="button" className="btn cta_outline_btn">
          <Icon icon="uil:share" />
          Share form to candidate
        </button>
      </div>
      <form
        id="personal-data-entry-form"
        onSubmit={handleSubmit}
        className="section"
      >
        <section className="section">
          <div className="form_section_title">Personal Details</div>
          <div className="row">
            <div className="column section--full">
              <div className="row">
                <div className="label label-gray">Full Name</div>
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  className={`input ${styles.input}`}
                  placeholder="Enter Full Name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Date of Birth</div>
                <input
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  className={`input ${styles.input}`}
                  placeholder="dd/mm/yyyy"
                  value={formData.date_of_birth}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Age</div>
                <input
                  type="number"
                  name="age"
                  id="age"
                  className={`input ${styles.input}`}
                  placeholder="Enter Age"
                  value={formData.age}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Gender</div>
                <input
                  name="gender"
                  id="gender"
                  className={`input ${styles.input}`}
                  value={formData.gender}
                  onChange={handleInputChange}
                  placeholder="Enter Gender"
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className="column section--full">
              <div className="row">
                <div className="label label-gray">Contact Number</div>
                <input
                  type="tel"
                  name="contact_number"
                  id="contact_number"
                  className={`input ${styles.input}`}
                  placeholder="Enter Contact Number"
                  value={formData.contact_number}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Email ID</div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`input ${styles.input}`}
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Residential address</div>
                <input
                  type="text"
                  name="residential_address"
                  id="residential_address"
                  className={`input ${styles.input}`}
                  placeholder="Enter Address"
                  value={formData.residential_address}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Permanent Address</div>
                <input
                  type="text"
                  name="permanent_address"
                  id="permanent_address"
                  className={`input ${styles.input}`}
                  placeholder="Enter Address"
                  value={formData.permanent_address}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">Nationality</div>
                <input
                  type="text"
                  name="nationality"
                  id="nationality"
                  className={`input ${styles.input}`}
                  placeholder="Enter Nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  required
                  disabled={personalDetailsVerified}
                />
              </div>
              <div className="row">
                <div className="label label-gray">
                  Medical Condition (if any)
                </div>
                <input
                  type="text"
                  name="medical_condition"
                  id="medical_condition"
                  className={`input ${styles.input}`}
                  placeholder="Enter Medical Condition"
                  value={formData.medical_condition}
                  onChange={handleInputChange}
                  disabled={personalDetailsVerified}
                />
              </div>
            </div>
          </div>
          <div className="btn_group btn_group--end btn_group--dense">
            <button
              type="button"
              className="btn cta_btn"
              onClick={verifyPersonalDetails}
            >
              Submit & Next
            </button>
          </div>
        </section>
        <section className="section">
          <div className="section_title_wrapper">
            <h3 className="section_title section_title--uppercase">
              Document Collection
            </h3>
            <button type="button" className="btn ghost_btn">
              + Add
            </button>
          </div>
          <div className="column">
            <div className={styles.form_row}>
              <div className="label">Experience Letter</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Relieving Letter</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Proof of Age</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Education Qualification Certificates</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">SSC</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">HSC</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Graduation Certificate</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Post Graduation Certificate</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Salary slip of previous employer</div>
              <div className="metadata row">
                <span>Uploaded by candidate</span>
                <span>dd/mm/yyyy hh:mm</span>
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section_title_wrapper">
            <h3 className="section_title section_title--uppercase">
              NOC / BGV
            </h3>
            <button type="button" className="btn ghost_btn">
              + Add
            </button>
          </div>
          <div className="column">
            <div className="row">
              <div className="label">No Objection Certificate</div>
              <div className="btn_group btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className="row">
              <div className="label">Background Verification</div>
              <div className="btn_group btn_group--dense">
                <button type="button" className="btn reject">
                  Reject
                </button>
                <button type="button" className="btn approve">
                  Approve
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section_title_wrapper">
            <h3 className="section_title section_title--uppercase">Contract</h3>
            <button type="button" className="btn ghost_btn">
              + Add
            </button>
          </div>
          <div className="column">
            <div className="row">
              <div className="label">Employement Contract</div>
              <div className="btn_group btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
          </div>
          <div className="btn_group btn_group--end btn_group--dense">
            <button type="button" className="btn cta_btn">
              Next
            </button>
          </div>
        </section>
        <section className="section">
          <div className="section_title_wrapper">
            <h3 className="section_title section_title--uppercase">
              Biometric Input
            </h3>
            <button type="button" className="btn ghost_btn">
              + Add
            </button>
          </div>
          <div className="column">
            <div className="row">
              <div className="label">Add Biometrics</div>
              <input
                type="text"
                className="input"
                placeholder="Enter Employee Id"
                value={formData.employee_id}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="btn_group btn_group--end btn_group--dense">
            <button type="button" className="btn cta_btn">
              Next
            </button>
          </div>
        </section>
        <section className="section">
          <div className="section_title_wrapper">
            <h3 className="section_title section_title--uppercase">
              Joining Kit Handover
            </h3>
            <button type="button" className="btn ghost_btn">
              + Add
            </button>
          </div>
          <div className="column">
            <div className={styles.form_row}>
              <div className="label">Welcome Kit</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end">
                <input type="checkbox" className="checkbox" />
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Company Policy Kit</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end">
                <input type="checkbox" className="checkbox" />
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Helment/Raincoat</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end">
                <input type="checkbox" className="checkbox" />
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Document 1</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Document 2</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
            <div className={styles.form_row}>
              <div className="label">Document 3</div>
              <div className="metadata">dd/mm/yyyy hh:mm</div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  View
                </Link>
                <button type="button" className="btn cta_outline_btn">
                  Upload
                </button>
              </div>
            </div>
          </div>
          <div className="btn_group btn_group--end btn_group--dense">
            <button type="button" className="btn cta_btn">
              Submit & Complete Recruitment
            </button>
          </div>
        </section>
      </form>
    </section>
  );
}

export default PersonalDataEntry;
