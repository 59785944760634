import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AccountsTable.module.css";

const staticCreditsData = [
  {
    id: 1,
    owner_name: "John Doe",
    company_name: "ABC Company",
    invoices: [
      {
        id: 1,
        invoice_number: "1233453",
      },
      {
        id: 2,
        invoice_number: "438768974",
      },
      {
        id: 3,
        invoice_number: "3804",
      },
      {
        id: 4,
        invoice_number: "39840",
      },
      {
        id: 5,
        invoice_number: "3894",
      },
      {
        id: 6,
        invoice_number: "42979",
      },
    ],
    amount: [
      {
        id: 1,
        amount: "₹ 50,000",
      },
      {
        id: 2,
        amount: "₹ 60,000",
      },
      {
        id: 3,
        amount: "₹ 70,000",
      },
      {
        id: 4,
        amount: "₹ 80,000",
      },
    ],
    reference_number: [
      { id: 1, reference_number: "1233453" },
      {
        id: 2,
        reference_number: "438768974",
      },
      {
        id: 3,
        reference_number: "3804",
      },
      {
        id: 4,
        reference_number: "39840",
      },
    ],
    due_date: "01/01/2023",
    late_days: "2",
  },
  {
    id: 2,
    owner_name: "John Doe",
    company_name: "ABC Company",
    invoices: [
      {
        id: 1,
        invoice_number: "1233453",
      },
      {
        id: 2,
        invoice_number: "438768974",
      },
      {
        id: 3,
        invoice_number: "3804",
      },
      {
        id: 4,
        invoice_number: "39840",
      },
      {
        id: 5,
        invoice_number: "3894",
      },
    ],
    amount: [
      {
        id: 1,
        amount: "₹ 50,000",
      },
      {
        id: 2,
        amount: "₹ 60,000",
      },
      {
        id: 3,
        amount: "₹ 70,000",
      },
      {
        id: 4,
        amount: "₹ 80,000",
      },
    ],
    reference_number: [
      { id: 1, reference_number: "1233453" },
      {
        id: 2,
        reference_number: "438768974",
      },
      {
        id: 3,
        reference_number: "3804",
      },
      {
        id: 4,
        reference_number: "39840",
      },
    ],
    due_date: "01/01/2023",
    late_days: "2",
  },
  {
    id: 3,
    owner_name: "John Doe",
    company_name: "ABC Company",
    invoices: [
      {
        id: 1,
        invoice_number: "1233453",
      },
      {
        id: 2,
        invoice_number: "438768974",
      },
      {
        id: 3,
        invoice_number: "3804",
      },
      {
        id: 4,
        invoice_number: "39840",
      },
      {
        id: 5,
        invoice_number: "3894",
      },
      {
        id: 6,
        invoice_number: "42979",
      },
    ],
    amount: [
      {
        id: 1,
        amount: "₹ 50,000",
      },
      {
        id: 2,
        amount: "₹ 60,000",
      },
      {
        id: 3,
        amount: "₹ 70,000",
      },
      {
        id: 4,
        amount: "₹ 80,000",
      },
    ],
    reference_number: [
      { id: 1, reference_number: "1233453" },
      {
        id: 2,
        reference_number: "438768974",
      },
      {
        id: 3,
        reference_number: "3804",
      },
      {
        id: 4,
        reference_number: "39840",
      },
    ],
    due_date: "01/01/2023",
    late_days: "2",
  },
];

function CreditsPage() {
  const [creditsData, setCreditsData] = useState(staticCreditsData);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...creditsData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setCreditsData(sortedData);
  };

  const creditsTableHeaders = [
    { id: "owner_name", label: "Owner Name" },
    { id: "company_name", label: "Company Name" },
    { id: "invoices", label: "Invoices" },
    { id: "amount", label: "Amount" },
    { id: "reference_number", label: "Reference Number" },
    { id: "due_date", label: "Due Date" },
    { id: "late_days", label: "Late Days" },
    { id: "action", label: "More Details" },
  ];

  return (
    <>
      <h2 className="section_title">Credits</h2>
      <section className="section">
        <h3 className="section_title">Payment Received</h3>
        <div className="data_table data_table_8">
          <div className="data_table_header">
            {creditsTableHeaders.map((header) => (
              <div key={header.id} className="data_table_header_cell">
                {header.label}
                {header.id !== "action" && (
                  <button
                    type="button"
                    className="sort_btn"
                    onClick={() => handleSort(header.id)}
                  >
                    <Icon
                      icon="ri:arrow-drop-down-line"
                      className={`sort_icon ${
                        sortConfig.key === header.id &&
                        sortConfig.direction === "asc"
                          ? "asc"
                          : "desc"
                      }`}
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className={`data_table_body ${styles.data_table_body}`}>
            {creditsData.map((credit) => (
              <div
                key={credit.id}
                className={`data_table_row ${styles.table_row}`}
              >
                <div className="data_table_cell">{credit.owner_name}</div>
                <div className="data_table_cell">{credit.company_name}</div>
                <div className={`data_table_cell ${styles.table_cell}`}>
                  {credit.invoices.map((invoice) => (
                    <div key={invoice.id}>{invoice.invoice_number}</div>
                  ))}
                </div>
                <div className={`data_table_cell ${styles.table_cell}`}>
                  {credit.amount.map((amt) => (
                    <div key={amt.id}>{amt.amount}</div>
                  ))}
                </div>
                <div className={`data_table_cell ${styles.table_cell}`}>
                  {credit.reference_number.map((ref) => (
                    <div key={ref.id}>{ref.reference_number}</div>
                  ))}
                </div>
                <div className="data_table_cell">{credit.due_date}</div>
                <div className="data_table_cell">{credit.late_days}</div>
                <div className="data_table_cell">
                  <Link to={`/credits/view/${credit.id}`} className="ghost_btn">
                    View
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default CreditsPage;
