
export const UserRoles = {
  ANALYST: "analyst",
  EMPLOYEE: "employee",
  ADMIN: "admin",
  ACCOUNTS: "accounts",
};

export const sidebarMenu = [
  { title: "My Jobs", icon: "material-symbols:home-work-outline", link: "/" },
  {
    title: "Attendance & Leaves",
    icon: "f7:hand-raised",
    link: "/leave-management",
  },
  { title: "Vouchers", icon: "mdi:voucher-outline", link: "/vouchers" },
  {
    title: "Letters & Certificates",
    icon: "lineicons:certificate-badge-1",
    link: "/letters-certificates",
  },
  { title: "Calendar", icon: "icon-park-outline:calendar", link: "/calendar" },
  {
    title: "Discipline Board",
    icon: "codicon:feedback",
    link: "/discipline-board",
  },
];

export const sidebarAdminMenu = [
  {
    title: "Admin Dashboard",
    icon: "fa6-solid:hands-holding",
    link: "/",
  },
  {
    title: "Candidates & Employees",
    icon: "clarity:employee-group-line",
    link: "/candidates-employees",
  },
  {
    title: "Hiring Request",
    icon: "tdesign:user-search",
    link: "/hiring-request",
  },
  {
    title: "Interview",
    icon: "hugeicons:conversation",
    link: "/interview",
  },
  {
    title: "On Boarding Process",
    icon: "fluent-mdl2:onboarding",
    link: "/onboarding",
  },
  {
    title: "Salary Board",
    icon: "solar:hand-money-linear",
    link: "/salaryboard",
  },
  {
    title: "Admin Module",
    icon: "tdesign:user-setting",
    link: "/admin-module",
  },
  {
    title: "Updating Data",
    icon: "radix-icons:update",
    link: "/update-data",
  },
  {
    title: "Attendance & Leaves",
    icon: "f7:hand-raised",
    link: "/leave-management",
  },
  { title: "Vouchers", icon: "mdi:voucher-outline", link: "/vouchers" },
  {
    title: "Letters & Certificates",
    icon: "lineicons:certificate-badge-1",
    link: "/letters-certificates",
  },
  { title: "Calendar", icon: "icon-park-outline:calendar", link: "/calendar" },
  {
    title: "Discipline Board",
    icon: "codicon:feedback",
    link: "/discipline-board",
  },
];

export const sidebarAccountsMenu = [
  {
    title: "Accounts",
    icon: "material-symbols:home-work-outline",
    link: "/",
    submenu: [
      {
        title: "Credits",
        link: "/credits",
        icon: "wpf:banknotes",
      },
      {
        title: "Debits",
        link: "/debits",
        icon: "mingcute:bill-2-line",
      },
    ],
  },
  {
    title: "Attendance & Leaves",
    icon: "f7:hand-raised",
    link: "/leave-management",
  },
  { title: "Vouchers", icon: "mdi:voucher-outline", link: "/vouchers" },
  {
    title: "Letters & Certificates",
    icon: "lineicons:certificate-badge-1",
    link: "/letters-certificates",
  },
  { title: "Calendar", icon: "icon-park-outline:calendar", link: "/calendar" },
  {
    title: "Discipline Board",
    icon: "codicon:feedback",
    link: "/discipline-board",
  },
];
