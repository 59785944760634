// App.js
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { MainLayout } from "./layouts";
import {
  Dashboard,
  JobView,
  LeaveManagement,
  LettersCertificatesPage,
  Login,
  VouchersPage,
} from "./pages";
import { CreditsPage, CreditsView, DebitsPage } from "./pages/accounts";
import { EmployeesCandidatesPage } from "./pages/admin";
import Users from "./pages/Users";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/accounts/credits" element={<Credits />} /> */}

        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs/:id"
            element={
              <PrivateRoute>
                <JobView />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidates-employees"
            element={
              <PrivateRoute>
                <EmployeesCandidatesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/leave-management"
            element={
              <PrivateRoute>
                <LeaveManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/vouchers"
            element={
              <PrivateRoute>
                <VouchersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/letters-certificates"
            element={
              <PrivateRoute>
                <LettersCertificatesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/credits"
            element={
              <PrivateRoute>
                <CreditsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/credits/view/:id"
            element={
              <PrivateRoute>
                <CreditsView />
              </PrivateRoute>
            }
          />
          <Route
            path="/debits"
            element={
              <PrivateRoute>
                <DebitsPage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
