import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "./JobCertificates.module.css";

const JobCertificates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStage = searchParams.get("stage");
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  useEffect(() => {
    const isCurrentStage = currentStage === "certificates";
    setIsSectionVisible(isCurrentStage);
  }, [currentStage]);

  const handleSectionToggle = () => {
    setIsSectionVisible((prev) => !prev);
  };

  const handleAddCertificate = () => {};

  return (
    <section className={`section ${styles.section}`}>
      <div className="section_header">
        <div className="section_title">Project Certificates</div>
        <div className={styles.btn_group}>
          <button className="btn ghost_btn" onClick={handleAddCertificate}>
            + Add Certificate
          </button>
          <button onClick={handleSectionToggle} className="btn toggle_btn">
            <img
              src="/assets/images/icons/mingcute_down-line.svg"
              alt="Toggle"
              className={`toggle_icon ${isSectionVisible ? "" : "hide"}`}
            />
          </button>
        </div>
      </div>
      {isSectionVisible ? (
        <>
          <div className="section_inner_container section--full">
            <div className="row">
              <span>Certificate 1</span>
              <div className="btn_group btn_group--end">
                <Link to="#" className="ghost_btn">
                  Preview
                </Link>
                <button type="button" className="btn cta_btn">
                  <img
                    src="/assets/images/icons/majesticons_share-line.svg"
                    alt="Share"
                  />
                  Share Certificate
                </button>
              </div>
            </div>
            <div className="row">
              <span>Certificate 2</span>
              <div className="btn_group btn_group--end">
                <Link to="#" className="ghost_btn">
                  Preview
                </Link>
                <button type="button" className="btn cta_btn">
                  <img
                    src="/assets/images/icons/majesticons_share-line.svg"
                    alt="Share"
                  />
                  Share Certificate
                </button>
              </div>
            </div>
          </div>
          <section className="section section--row">
            <section className="section_inner_container section--full">
              <div className={styles.section_title}>Edit Details</div>
              <form
                id="job_certificates_form"
                className={`form ${styles.form}`}
              >
                <div className={styles.form_title}>Edit Details</div>
                <input
                  type="text"
                  className="input"
                  placeholder="Enter Certificate Name"
                />
                <div className="input_group">
                  <input type="text" className="input" placeholder="Field" />
                  <input type="text" className="input" placeholder="Field" />
                  <input type="text" className="input" placeholder="Field" />
                </div>
                <section className={styles.btn_group}>
                  <div className={`btn_group--end ${styles.btn_group}`}>
                    <Link to="#" className="ghost_btn">
                      Preview
                    </Link>
                    <button type="submit" className="btn cta_btn">
                      Save
                    </button>
                  </div>
                </section>
              </form>
            </section>
            <section className="section_inner_container section--full">
              <div className={styles.section_title}>Preview</div>
              <div className={styles.form_data_preview}>
                <div className={styles.btn_group}>
                  <button type="button" className="btn ghost_btn">
                    <img
                      src="/assets/images/icons/lucide_copy.svg"
                      alt="Copy"
                    />
                    copy
                  </button>
                  <button type="button" className="btn ghost_btn">
                    <img
                      src="/assets/images/icons/lucide_save.svg"
                      alt="Save"
                    />
                    save as PDF
                  </button>
                </div>
                <p className="preview_text">[Field]</p>
                <p className="preview_text">[Field]</p>
                <p className="preview_text">[Field]</p>
                <p className="preview_text">[Field]</p>
                <p className="preview_text">[Field]</p>
                <p className="preview_text">[Field]</p>
              </div>
            </section>
          </section>
        </>
      ) : (
        ""
      )}
    </section>
  );
};
export default JobCertificates;
