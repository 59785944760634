import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./JobAssignForm.module.css";

const JobAssignForm = ({ jobData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStage = searchParams.get("stage");
  const [showAssignJobForm, setShowAssignJobForm] = useState(false);
  const [newDocumentName, setNewDocumentName] = useState("");
  const [priority, setPriority] = useState("");

  useEffect(() => {
    const isCurrentStage = currentStage === "work-allotment";
    setShowAssignJobForm(isCurrentStage);
  }, [currentStage]);

  const [formData, setFormData] = useState({
    job_id: jobData.id,
    job_code: jobData.job_number,
    job_name: jobData.job_name,
    work_title: "",
    department: "",
    employee: "",
    hours: "",
    minutes: "",
    priority: "",
    note: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log(formData);

  const departmentOptions = [
    { value: 1, label: "Department 1" },
    { value: 2, label: "Department 2" },
    { value: 3, label: "Department 3" },
  ];

  const employeeOptions = [
    { value: 1, label: "Employee 1" },
    { value: 2, label: "Employee 2" },
    { value: 3, label: "Employee 3" },
  ];

  const handlePriorityClick = (level) => {
    setPriority(level);
  };

  const handleDocumentUpload = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <>
      {showAssignJobForm && (
        <form id="assign-work" className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.input_wrapper}>
            <span className={`label ${styles.label}`}>Job Code</span>
            <span className={styles.value}># {jobData.job_number}</span>
          </div>
          <div className={styles.input_wrapper}>
            <span className="label">Job Name</span>
            <span className={styles.value}>
              {jobData.job_name ?? `Job ${jobData.id}`}
            </span>
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="work-title" className="label">
              Work Title
            </label>
            <input
              type="text"
              className={`input ${styles.input}`}
              name="work-title"
              onChange={handleChange}
              value={formData.work_title}
              placeholder="Enter work title"
              required
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="department" className="label">
              Department
            </label>
            <select
              className={`select ${styles.select}`}
              name="department"
              onChange={handleChange}
              value={formData.department}
              required
            >
              <option value="" disabled>
                Select department
              </option>
              {departmentOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <section className={styles.input_wrapper}>
            <label htmlFor="employee" className="label">
              Employee
            </label>
            <select
              className={`select ${styles.select}`}
              name="employee"
              required
            >
              <option value="" disabled>
                Select employee
              </option>
              {employeeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </section>
          <section className={styles.input_wrapper}>
            <label className="label">Enter work timeline</label>
            <div className={styles.time_input}>
              <input
                type="number"
                name="hours"
                className={`input ${styles.input}`}
                required
                onChange={handleChange}
                value={formData.hours}
                placeholder="Hours"
                min={1}
                max={12}
              />
              <span>:</span>
              <input
                type="number"
                name="minutes"
                className={`input ${styles.input}`}
                required
                onChange={handleChange}
                value={formData.minutes}
                placeholder="Minutes"
                min={0}
                max={59}
              />
            </div>
          </section>
          <section className={styles.input_wrapper_full}>
            <span className="label">Select Priority</span>
            <div className={styles.input_group_full}>
              {["Urgent", "High", "Medium", "Low"].map((level) => (
                <button
                  key={level}
                  type="button"
                  className={`${styles["priority-btn"]} ${
                    styles[level.toLowerCase()]
                  } ${priority === level ? styles.selected : ""}`}
                  onClick={() => handlePriorityClick(level)}
                >
                  {level}
                </button>
              ))}
            </div>
          </section>
          <section className={styles.input_wrapper_full}>
            <span className="label">Upload Documents</span>
            <div className={`${styles.input_group_full} ${styles.documents}`}>
              <input
                type="text"
                name="document_name"
                onChange={(e) => setNewDocumentName(e.target.value)}
                value={newDocumentName}
                placeholder="Enter document name"
                className={`input ${styles.input}`}
              />
              <button
                type="button"
                className={styles.upload_btn}
                onClick={handleDocumentUpload}
              >
                Upload
                <input type="file" name="document" hidden />
              </button>
            </div>
          </section>
          <section className={styles.input_wrapper_full}>
            <span className="label">Note</span>
            <textarea
              className={`textarea ${styles.textarea}`}
              name="note"
              placeholder="Enter note"
              onChange={handleChange}
              value={formData.note}
              rows={1}
              required
            />
          </section>
          <section className={styles.btn_group}>
            <button
              type="button"
              className={styles.cancel_btn}
              onClick={() => setShowAssignJobForm(false)}
            >
              Cancel
            </button>
            <button type="submit" className={styles.cta_btn}>
              Add Work
            </button>
          </section>
        </form>
      )}
    </>
  );
};

export default JobAssignForm;
