import { useEffect, useState } from "react";
import { UserRoles } from "../lib/constants";
import { AccountsDashboard } from "./accounts";
import { AdminDashboard } from "./admin";
import { AnalystDashboard } from "./analyst";
import { EmployeeDashboard } from "./employee";

function Dashboard() {
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("role"));
    setRole(userRole);
  }, []);

  if (role === UserRoles.ANALYST) return <AnalystDashboard />;
  if (role === UserRoles.EMPLOYEE) return <EmployeeDashboard />;
  if (role === UserRoles.ACCOUNTS) return <AccountsDashboard />;
  if (role === UserRoles.ADMIN) return <AdminDashboard />;

  return (
    <div>
      <h1>Welcome to the Dashboard!</h1>
    </div>
  );
}

export default Dashboard;
