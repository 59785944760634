import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import styles from "./EmployeeDetails.module.css";

function EmployeeDetails({ employeeData }) {
  const {
    employee_name,
    employee_email,
    employee_contact,
    employee_age,
    employee_gender,
    employee_linkedin,
    current_salary,
    employment_period,
  } = employeeData;
  return (
    <>
      <section className="section">
        <div className="section_title_wrapper">
          <h3 className="section_title">{employee_name} details</h3>
          <button className="btn ghost_btn">
            Edit
            <Icon icon="carbon:edit" />
          </button>
        </div>
        <div className="section section--row">
          <div className="column section--full">
            <div className="row">
              <div className="label label-gray">Employee Name</div>
              <div className="value">{employee_name}</div>
            </div>
            <div className="row">
              <div className="row section--full">
                <div className="label label-gray">Age</div>
                <div className="value">{employee_age}</div>
              </div>
              <div className="row section--full">
                <div className="label label-gray">Gender</div>
                <div className="value">{employee_gender}</div>
              </div>
            </div>
            <div className="row">
              <div className="label label-gray">Contact Number</div>
              <div className="value">{employee_contact}</div>
            </div>
            <div className="row">
              <div className="label label-gray">Email ID</div>
              <div className="value">{employee_email}</div>
            </div>
          </div>
          <div className="vertical_divider"></div>
          <div className="column section--full">
            <div className="row">
              <div className="label label-gray">Linked In</div>
              <div className="value">{employee_linkedin}</div>
            </div>
            <div className="row">
              <div className="label label-gray">Employment Period</div>
              <div className="value">{employment_period}</div>
            </div>
            <div className="row">
              <div className="label label-gray section--full">
                Current Salary
              </div>
              <div className="btn_group btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  view details
                </Link>
                <span className="value">₹ {current_salary} LPA</span>
              </div>
            </div>
            <div className="row">
              <div className="label label-gray">Resume</div>
              <div className="value">
                <Link to="" className="ghost_btn">
                  view
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section_title_wrapper">
          <h3 className="section_title">Payslip</h3>
          {/* to do: change to select */}
          <div className="btn cta_outline_btn">
            <Icon icon="ri:arrow-drop-down-line" />
            Select Month
          </div>
        </div>
        <div className="column">
          <div className="row">
            <div className="label">October 2024</div>
            <div className="btn_group btn_group--end btn_group--dense">
              <Link to="" className="ghost_btn">
                view
              </Link>
              <button className="btn cta_outline_btn">Download</button>
            </div>
          </div>
          <div className="row">
            <div className="label">November 2024</div>
            <div className="btn_group btn_group--end btn_group--dense">
              <Link to="" className="ghost_btn">
                view
              </Link>
              <button className="btn cta_outline_btn">Download</button>
            </div>
          </div>
          <div className="row">
            <div className="label">December 2024</div>
            <div className="btn_group btn_group--end btn_group--dense">
              <Link to="" className="ghost_btn">
                view
              </Link>
              <button className="btn cta_outline_btn">Download</button>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <section className="section_title_wrapper">
          <h3 className="section_title">Current Salary Details</h3>
        </section>
        <section className={styles.salary_details}>
          <div className={styles.table}>
            <div className={styles.table_header}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Deductions</div>
                <div className={styles.table_cell}>Amount</div>
                <div className={styles.table_cell}>YTD</div>
              </div>
            </div>
            <div className={styles.table_body}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Basic</div>
                <div className={styles.table_cell}>₹ 43,750</div>
                <div className={styles.table_cell}>₹ 87,500</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>House Rent Allowance</div>
                <div className={styles.table_cell}>₹ 21,875</div>
                <div className={styles.table_cell}>₹ 43,750</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Conveyance Allowance</div>
                <div className={styles.table_cell}>₹ 6,000</div>
                <div className={styles.table_cell}>₹ 12,000</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Training Allowance</div>
                <div className={styles.table_cell}>₹ 4,000</div>
                <div className={styles.table_cell}>₹ 8,000</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Fixed Allowance</div>
                <div className={styles.table_cell}>₹ 6,625</div>
                <div className={styles.table_cell}>₹ 13,250</div>
              </div>
            </div>
            <div className={styles.table_footer}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Gross Earnings</div>
                <div className={styles.table_cell}>₹ 82,250</div>
                <div></div>
              </div>
            </div>
          </div>
          <div className={styles.table}>
            <div className={styles.table_header}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Earnings</div>
                <div className={styles.table_cell}>Amount</div>
                <div className={styles.table_cell}>YTD</div>
              </div>
            </div>
            <div className={styles.table_body}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>EPF Contribution</div>
                <div className={styles.table_cell}>₹ 5,250</div>
                <div className={styles.table_cell}>₹ 10,500</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Professional Tax</div>
                <div className={styles.table_cell}>₹ 1,250</div>
                <div className={styles.table_cell}>₹ 1,250</div>
              </div>
            </div>
            <div className={styles.table_footer}>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>Total Earnings</div>
                <div className={styles.table_cell}>₹ 6,500</div>
                <div></div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default EmployeeDetails;
