import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApiService } from "../../../services";

function OnboardingRound2({ candidateData }) {
  const { post } = useApiService();
  const [technicalKnowledgeScore, setTechnicalKnowledgeScore] = useState(0);
  const [technicalKnowledgeRemark, setTechnicalKnowledgeRemark] = useState("");
  const [teamPlayerScore, setTeamPlayer] = useState(0);
  const [teamPlayerRemark, setTeamPlayerRemark] = useState("");
  const [problemSolvingScore, setProblemSolvingScore] = useState(0);
  const [problemSolvingRemark, setProblemSolvingRemark] = useState("");
  const [peopleSkillsScore, setPeopleSkillsScore] = useState(0);
  const [peopleSkillsRemark, setPeopleSkillsRemark] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [totalScoreRemark, setTotalScoreRemark] = useState("");

  useEffect(() => {
    const total_score =
      parseInt(technicalKnowledgeScore) +
      parseInt(teamPlayerScore) +
      parseInt(problemSolvingScore) +
      parseInt(peopleSkillsScore);
    setTotalScore(total_score);
  }, [
    technicalKnowledgeScore,
    teamPlayerScore,
    problemSolvingScore,
    peopleSkillsScore,
  ]);

  const [status, setStatus] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !technicalKnowledgeScore ||
      !teamPlayerScore ||
      !problemSolvingScore ||
      !peopleSkillsScore ||
      !totalScore
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const formData = {
      candidate_id: candidateData.id,
      round_2: {
        technical_knowledge: technicalKnowledgeScore,
        technical_knowledge_remark: technicalKnowledgeRemark,
        team_player: teamPlayerScore,
        team_player_remark: teamPlayerRemark,
        problem_solving: problemSolvingScore,
        problem_solving_remark: problemSolvingRemark,
        people_skills: peopleSkillsScore,
        people_skills_remark: peopleSkillsRemark,
        total_score: totalScore,
        total_score_remark: totalScoreRemark,
        status: status,
      },
    };

    console.log(formData);

    // try {
    //   const response = post("/api/admin/onboarding/round/2", formData);
    //   if (response.success) {
    //     toast.success("Round 2 submitted successfully.");
    //   } else {
    //     toast.error("Failed to submit round 2. Please try again.");
    //   }
    // } catch (error) {
    //   console.error("Failed to submit round 2:", error);
    //   toast.error("Failed to submit round 2. Please try again.");
    // }
  };

  return (
    <form id="onboarding-round-2" onSubmit={handleSubmit} className="section">
      <div className="section_title_wrapper">
        <h3 className="section_title section_title--uppercase">
          Round 2 - Manager
        </h3>
        <span>Candidate Test Score</span>
      </div>
      <div className="score_input_row">
        <label htmlFor="technical_knowledge" className="label">
          Technical Knowledge
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="technical_knowledge"
            name="technical_knowledge"
            value={technicalKnowledgeScore}
            onChange={(e) => setTechnicalKnowledgeScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="technical_knowledge_remark"
          placeholder="Enter Remark"
          id="technical_knowledge_remark"
          value={technicalKnowledgeRemark}
          onChange={(e) => setTechnicalKnowledgeRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="team_player" className="label">
          Team Player
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="team_player"
            name="team_player"
            value={teamPlayerScore}
            onChange={(e) => setTeamPlayer(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="team_player_remark"
          placeholder="Enter Remark"
          id="team_player_remark"
          value={teamPlayerRemark}
          onChange={(e) => setTeamPlayerRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="problem_solving" className="label">
          Problem Solving{" "}
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="problem_solving"
            name="problem_solving"
            value={problemSolvingScore}
            onChange={(e) => setProblemSolvingScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="problem_solving_remark"
          placeholder="Enter Remark"
          id="problem_solving_remark"
          value={problemSolvingRemark}
          onChange={(e) => setProblemSolvingRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="people_skills" className="label">
          People Skills
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="people_skills"
            name="people_skills"
            value={peopleSkillsScore}
            onChange={(e) => setPeopleSkillsScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="people_skills_remark"
          placeholder="Enter Remark"
          id="people_skills_remark"
          value={peopleSkillsRemark}
          onChange={(e) => setPeopleSkillsRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="total_score" className="label bold">
          Total Score
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="total_score"
            name="total_score"
            value={totalScore}
            className="input score_input"
            min="0"
            max="60"
            disabled
            required
          />
          <span>/60</span>
        </div>
        <input
          type="text"
          name="total_score_remark"
          placeholder="Enter Remark"
          id="total_score_remark"
          value={totalScoreRemark}
          onChange={(e) => setTotalScoreRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="btn_group btn_group--dense">
        <button
          type="submit"
          className="btn reject"
          onClick={() => setStatus("rejected")}
        >
          Reject
        </button>
        <button
          type="submit"
          className="btn approve"
          onClick={() => setStatus("approved")}
        >
          Approve
        </button>
      </div>
    </form>
  );
}

export default OnboardingRound2;
