import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services";
import styles from "./Header.module.css";

const Header = () => {
  const navigate = useNavigate(); // Initialize navigate
  const { logout } = useAuth();

  const [user, setUser] = useState({
    name: "Full Name 1",
  });
  const [role, setRole] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    // Retrieve user data from localStorage
    const userData = localStorage.getItem("userData");
    const role = localStorage.getItem("role");
    if (userData) setUser({ ...JSON.parse(userData) });
    if (role) setRole(JSON.parse(role));
  }, []);

  const handleLogout = async () => {
    const isLoggedOut = await logout();
    if (isLoggedOut) navigate("/login");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <header className={styles.header}>
      <div className={styles.search_container}>
        <img src="/assets/images/mingcute_search-line.svg" alt="Search Icon" />
        <input
          type="text"
          placeholder="Search"
          className={styles.search_input}
        />
      </div>
      <div className={styles.user}>
        <div className={styles.icons}>
          <span className={styles.icon}>
            <img
              src="/assets/images/material-symbols_mail-outline.svg"
              alt="Mail Icon"
            />
          </span>
          <span className={styles.icon}>
            <img
              src="/assets/images/carbon_notification-new.svg"
              alt="Notification Icon"
            />
          </span>
        </div>
        <div className={styles.profile} onClick={toggleDropdown}>
          <img
            className={styles.profile_image}
            src="/assets/images/fluent-emoji_man-light.svg"
            alt="User Profile"
          />
          <div className={styles.profile_info}>
            <span className={styles.profile_name}>{user.name}</span>
            <span className={styles.profile_role}>{role}</span>
            {isDropdownOpen ? (
              <div className={styles.dropdown}>
                <button
                  className={styles.dropdown_item}
                  onClick={() => navigate("/edit_profile")}
                >
                  Edit Profile
                </button>
                <button className={styles.dropdown_item} onClick={handleLogout}>
                  Logout
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
