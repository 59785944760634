import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function EmployeesTable({ employeesData, setSelectedEmployee }) {
  const [employees, setEmployees] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  useEffect(() => {
    if (employeesData && employeesData.length > 0) {
      setEmployees(employeesData);
    }
  }, [employeesData]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedEmployees = [...employees].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setEmployees(sortedEmployees);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const handleViewDetails = (employeeId) => {
    setSearchParams({ page: "employees", employee_id: employeeId });
    setSelectedEmployee(employeeId);
  };

  const employeeTableHeaders = [
    { id: "employee_id", label: "Employee ID" },
    { id: "employee_name", label: "Employee Name" },
    { id: "employee_contact", label: "Contact Number" },
    { id: "employee_email", label: "Email ID" },
    { id: "department_name", label: "Department" },
    { id: "position_name", label: "Position" },
    { id: "employment_period", label: "Employment Period" },
    { id: "action", label: "More Details" },
  ];

  return (
    <>
      <div className="data_table data_table_8">
        <div className="data_table_header">
          {employeeTableHeaders.map((header) => (
            <div key={header.id} className="data_table_header_cell">
              {header.label}
              {header.id !== "action" && (
                <button
                  type="button"
                  className="sort_btn"
                  onClick={() => handleSort(header.id)}
                >
                  <Icon
                    icon="ri:arrow-drop-down-line"
                    className={`sort_icon ${
                      sortConfig.key === header.id &&
                      sortConfig.direction === "asc"
                        ? "asc"
                        : "desc"
                    }`}
                  />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="data_table_body">
          {employees.map((employee) => (
            <div
              key={employee.employee_id}
              className="data_table_row"
              onClick={() => handleViewDetails(employee.employee_id)}
            >
              <div className="data_table_cell">{employee.employee_id}</div>
              <div className="data_table_cell">{employee.employee_name}</div>
              <div className="data_table_cell">{employee.employee_contact}</div>
              <div className="data_table_cell">{employee.employee_email}</div>
              <div className="data_table_cell">{employee.department_name}</div>
              <div className="data_table_cell">{employee.position_name}</div>
              <div className="data_table_cell">
                {employee.employment_period}
              </div>
              <div className="data_table_cell action">
                <button
                  type="button"
                  className="btn ghost_btn"
                  onClick={() => handleViewDetails(employee.employee_id)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default EmployeesTable;
