import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { VouchersTable } from "../../components/admin";
import { useApiService } from "../../services";

function VouchersPage() {
  const { get } = useApiService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedVouchers, setSelectedVouchers] = useState(
    searchParams.get("vouchers")
  ); // active or closed
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get("status")
  ); // for closed vouchers, accepted or rejected

  const [vouchersData, setVouchersData] = useState(null);

  useEffect(() => {
    const currentVouchersSelected = searchParams.get("vouchers");
    if (!currentVouchersSelected) {
      setSearchParams({ vouchers: "active" });
      setSelectedVouchers("active");
    }

    if (currentVouchersSelected && currentVouchersSelected === "closed") {
      const selectedStatus = searchParams.get("status");
      if (!selectedStatus) {
        setSearchParams({ status: "accepted", vouchers: "closed" });
        setSelectedStatus("accepted");
      }
    }
  }, []);

  useEffect(() => {
    const getActiveVouchers = async () => {
      try {
        const response = await get("/api/admin/vouchers/active");
        if (response.success && response.data) {
          setVouchersData(response.data);
        }
        throw new Error("Failed to fetch vouchers data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch vouchers data. Please try again.");
      }
    };
    const getAcceptedClosedVouchers = async () => {
      try {
        const response = await get("/api/admin/voucheres/closed/accepted");
        if (response.success && response.data) {
          setVouchersData(response.data);
        }
        throw new Error("Failed to fetch vouchers data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch vouchers data. Please try again.");
      }
    };
    const getRejectedClosedVouchers = async () => {
      try {
        const response = await get("/api/admin/vouchers/closed/rejected");
        if (response.success && response.data) {
          setVouchersData(response.data);
        }
        throw new Error("Failed to fetch vouchers data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch vouchers data. Please try again.");
      }
    };
    // if (selectedVouchers === "active") {
    //   getActiveVouchers();
    // } else if (selectedVouchers === "closed") {
    //   if (selectedStatus === "accepted") {
    //     getAcceptedClosedVouchers();
    //   } else {
    //     getRejectedClosedVouchers();
    //   }
    // }
  }, [selectedVouchers, selectedStatus]);

  const handleVoucherTabChange = (tab) => {
    if (tab === "closed") {
      setSearchParams({ status: "accepted", vouchers: "closed" });
      setSelectedStatus("accepted");
    } else {
      setSearchParams({ vouchers: tab });
    }
    setSelectedVouchers(tab);
  };

  const handleStatusTabChange = (tab) => {
    setSelectedStatus(tab);
    searchParams.append("status", tab);
    setSearchParams({ status: tab, vouchers: "closed" });
  };

  const staticActiveVouchers = [
    {
      id: 5,
      employee_name: "John Doe",
      department_name: "HR",
      expense_type: "Travel Expense",
      amount: 1000,
      note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
    {
      id: 6,
      employee_name: "Jane Smith",
      department_name: "Finance",
      expense_type: "Site Visit",
      amount: 3000,
      note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
    {
      id: 7,
      employee_name: "Alice Johnson",
      department_name: "Marketing",
      expense_type: "Meeting",
      amount: 5000,
      note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
    {
      id: 8,
      employee_name: "Bob Brown",
      department_name: "Sales",
      expense_type: "Hotel Expenses",
      amount: 2000,
      note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
  ];

  const staticAcceptedVouchers = [
    {
      id: 1,
      employee_name: "John Doe",
      department_name: "HR",
      expense_type: "Travel Expense",
      amount: 1000,
      status: "Approve",
      applied_on: "2023-08-10",
    },
    {
      id: 2,
      employee_name: "Jane Smith",
      department_name: "Finance",
      status: "Approve",
      expense_type: "Site Visit",
      amount: 3000,
      applied_on: "2023-08-10",
    },
  ];

  const staticRejectedVouchers = [
    {
      id: 3,
      employee_name: "John Doe",
      department_name: "HR",
      expense_type: "Travel Expense",
      amount: 1000,
      rejection_reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      status: "Rejected",
      applied_on: "2023-08-10",
    },
    {
      id: 4,
      employee_name: "Jane Smith",
      department_name: "Finance",
      expense_type: "Site Visit",
      amount: 3000,
      rejection_reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      status: "Rejected",
      applied_on: "2023-08-10",
    },
  ];

  return (
    <>
      <div className="page_title_wrapper">
        <h1 className="page_title">Vouchers, Approvals & Office Supplies</h1>
        <div className="btn_group btn_group--end">
          <button className="btn cta_outline_btn">+ Request Voucher</button>
        </div>
      </div>
      <div className="toggle_group">
        <section className="toggle">
          <button
            type="button"
            className={`toggle_item ${
              selectedVouchers === "active" ? "active" : ""
            }`}
            onClick={() => handleVoucherTabChange("active")}
          >
            Active
          </button>
          <button
            type="button"
            className={`toggle_item ${
              selectedVouchers === "closed" ? "active" : ""
            }`}
            onClick={() => handleVoucherTabChange("closed")}
          >
            Closed
          </button>
        </section>
        {selectedVouchers === "closed" && (
          <section className="toggle">
            <button
              type="button"
              className={`toggle_item ${
                selectedStatus === "accepted" ? "active" : ""
              }`}
              onClick={() => handleStatusTabChange("accepted")}
            >
              Accepted
            </button>
            <button
              type="button"
              className={`toggle_item ${
                selectedStatus === "rejected" ? "active" : ""
              }`}
              onClick={() => handleStatusTabChange("rejected")}
            >
              Rejected
            </button>
          </section>
        )}
      </div>
      <VouchersTable
        vouchersData={
          vouchersData
            ? vouchersData
            : selectedVouchers === "active"
            ? staticActiveVouchers
            : selectedStatus === "accepted"
            ? staticAcceptedVouchers
            : staticRejectedVouchers
        }
        type={selectedVouchers}
        status={selectedStatus}
      />
    </>
  );
}

export default VouchersPage;
