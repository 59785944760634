import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import styles from "./JobDetails.module.css";
import JobForm from "./JobForm";

const JobDetails = ({ jobData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStage = searchParams.get("stage");
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [isJobCreateFormOpen, setIsJobCreateFormOpen] = useState(false);

  useEffect(() => {
    const isCurrentStage = currentStage === "project-details";
    setIsSectionVisible(isCurrentStage);
  }, [currentStage]);

  // Destructure jobData for easy access
  const {
    id,
    job_name,
    job_number,
    job_status,
    building_type,
    number_of_floors,
    total_amount,
    drafted_by,
    company_name,
    project_date,
    site_address,
    total_area,
    rate,
    designed_by,
    aadhaar_number,
    pan_number,
    owner_name,
    owner_mobile,
    owner_phone,
    owner_email,
    owner_office,
    architect_name,
    architect_mobile,
    architect_phone,
    architect_email,
    architect_office,
    client_name,
    client_mobile,
    client_phone,
    client_email,
    client_office,
    builder_name,
    builder_mobile,
    builder_phone,
    builder_email,
    builder_office,
  } = jobData;

  const handleAddWork = () => {
    setSearchParams({ stage: "work-allotment" });
  };

  const handleEditJob = () => {
    setIsJobCreateFormOpen(true);
  };

  const closeJobCreateForm = () => {
    setIsJobCreateFormOpen(false);
  };

  const handleSectionToggle = () => {
    setIsSectionVisible((prev) => !prev);
  };

  return (
    <>
      <div className={`section ${styles.section}`}>
        <div className={styles.section_header}>
          <div className="section_title">{job_name ?? `Job Name ${id}`}</div>
          <button
            className={`btn cta_outline_btn ${styles.cta_outline_btn}`}
            onClick={handleAddWork}
          >
            + Add work
          </button>
          <div className={styles.btn_group}>
            {isSectionVisible ? (
              <button onClick={handleEditJob} className="btn edit_btn">
                Edit <img src="/assets/images/icons/edit.svg" alt="Edit" />
              </button>
            ) : (
              ""
            )}
            <button
              onClick={handleSectionToggle}
              className="btn section_toggle_btn"
            >
              <img
                src="/assets/images/icons/mingcute_down-line.svg"
                alt="Toggle"
                className={`toggle_icon ${isSectionVisible ? "" : "hide"}`}
              />
            </button>
          </div>
        </div>
        {isSectionVisible ? (
          <section className="section">
            <section className="section section--row">
              <section className="section--full section_inner_container">
                <div className="row">
                  <div className="label">Job number</div>
                  <div className="value">{job_number}</div>
                </div>
                <div className="row">
                  <div className="label">Job name</div>
                  <div className="value">Job Name {id}</div>
                </div>
                <div className="row">
                  <div className="label">Job status</div>
                  <div className="value">
                    <select className="select" value={job_status} disabled>
                      <option value="Completed">Certification complete</option>
                      <option value="Not Started">Pending</option>
                      <option value="Ongoing">In Progress</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="label">Building type</div>
                  <div className="value">
                    <select className="select" value={building_type} disabled>
                      <option value="Commercial">Commercial building</option>
                      <option value="Residential">Residential building</option>
                      <option value="Industrial">Industrial building</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="label">No. of floors</div>
                  <div className="value">{number_of_floors}</div>
                </div>
                <div className="row">
                  <div className="label">Total amount</div>
                  <div className="value">₹ {total_amount}</div>
                </div>
                <div className="row">
                  <div className="label">Drafted by</div>
                  <div className="value">{drafted_by}</div>
                </div>
              </section>

              <div className="vertical-line"></div>
              <section className="section--full section_inner_container">
                <div className="row">
                  <div className="label">Company name</div>
                  <div className="value">{company_name}</div>
                </div>
                <div className="row">
                  <div className="label">Project date</div>
                  <div className="value">{project_date}</div>
                </div>
                <div className="row">
                  <div className="label">Site address</div>
                  <div className="value">{site_address}</div>
                </div>
                <div className="row">
                  <div className="label">Total area</div>
                  <div className="value">{total_area} Sq. ft.</div>
                </div>
                <div className="row">
                  <div className="label">Rate</div>
                  <div className="value">₹ {rate}</div>
                </div>
                <div className="row">
                  <div className="label">Designed by</div>
                  <div className="value">{designed_by}</div>
                </div>
                <div className="row">
                  <div className="label">Aadhaar number</div>
                  <div className="value upload_group">
                    <a href="/#view" className="upload_link">
                      view
                    </a>
                    <a href="/#upload" className="upload_link">
                      upload
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="label">PAN number</div>
                  <div className="value upload_group">
                    <a href="/#view" className="upload_link">
                      view
                    </a>
                    <a href="/#upload" className="upload_link">
                      upload
                    </a>
                  </div>
                </div>
              </section>
            </section>

            {/* Owner details */}
            <section className="section section--row">
              <section className="section--full section_inner_container">
                <div className="section_header">Owner details</div>
                <div className="row">
                  <div className="label">Owner name</div>
                  <div className="value">{owner_name}</div>
                </div>
                <div className="row">
                  <div className="label">Mobile number</div>
                  <div className="value">{owner_mobile}</div>
                </div>
                <div className="row">
                  <div className="label">Phone number</div>
                  <div className="value">{owner_phone}</div>
                </div>
                <div className="row">
                  <div className="label">Email address</div>
                  <div className="value">{owner_email}</div>
                </div>
                <div className="row">
                  <div className="label">Office address</div>
                  <div className="value">{owner_office}</div>
                </div>
              </section>

              <div className="vertical-line"></div>
              {/* Architect details */}
              <section className="section--full section_inner_container">
                <div className="section_header">Architect details</div>
                <div className="row">
                  <div className="label">Architect name</div>
                  <div className="value">{architect_name}</div>
                </div>
                <div className="row">
                  <div className="label">Mobile number</div>
                  <div className="value">{architect_mobile}</div>
                </div>
                <div className="row">
                  <div className="label">Phone number</div>
                  <div className="value">{architect_phone}</div>
                </div>
                <div className="row">
                  <div className="label">Email address</div>
                  <div className="value">{architect_email}</div>
                </div>
                <div className="row">
                  <div className="label">Office address</div>
                  <div className="value">{architect_office}</div>
                </div>
              </section>
            </section>

            {/* Client details */}
            <section className="section section--row">
              <section className="section--full section_inner_container">
                <div className="section_header">Client details</div>
                <div className="row">
                  <div className="label">Client name</div>
                  <div className="value">{client_name}</div>
                </div>
                <div className="row">
                  <div className="label">Mobile number</div>
                  <div className="value">{client_mobile}</div>
                </div>
                <div className="row">
                  <div className="label">Phone number</div>
                  <div className="value">{client_phone}</div>
                </div>
                <div className="row">
                  <div className="label">Email address</div>
                  <div className="value">{client_email}</div>
                </div>
                <div className="row">
                  <div className="label">Office address</div>
                  <div className="value">{client_office}</div>
                </div>
              </section>

              <div className="vertical-line"></div>
              {/* Developer/Builder details */}
              <section className="section--full section_inner_container">
                <div className="section_header">Developer/Builder details</div>
                <div className="row">
                  <div className="label">Developer name</div>
                  <div className="value">{builder_name}</div>
                </div>
                <div className="row">
                  <div className="label">Mobile number</div>
                  <div className="value">{builder_mobile}</div>
                </div>
                <div className="row">
                  <div className="label">Phone number</div>
                  <div className="value">{builder_phone}</div>
                </div>
                <div className="row">
                  <div className="label">Email address</div>
                  <div className="value">{builder_email}</div>
                </div>
                <div className="row">
                  <div className="label">Office address</div>
                  <div className="value">{builder_office}</div>
                </div>
              </section>
            </section>
          </section>
        ) : (
          ""
        )}
      </div>
      {isJobCreateFormOpen
        ? createPortal(
            <JobForm
              onCancel={closeJobCreateForm}
              onSubmitSuccess={closeJobCreateForm}
              isVisible={isJobCreateFormOpen}
              jobData={jobData}
            />,
            document.body
          )
        : ""}
    </>
  );
};
export default JobDetails;
