import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

function LeavesTable({ leavesData, type, status }) {
  const activeTableHeaders = [
    { id: "employee_name", label: "Employee Name" },
    { id: "department_name", label: "Department" },
    { id: "leave_type", label: "Leave Type" },
    { id: "leave_period", label: "Period" },
  ];

  const closedTableHeaders = [
    { id: "employee_name", label: "Employee Name" },
    { id: "department_name", label: "Department" },
    { id: "leave_type", label: "Leave Type" },
    { id: "leave_period", label: "Period" },
    { id: "status", label: "Status" },
  ];

  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState(leavesData);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleExpandRow = (leaveId) => {
    setExpandedRow(expandedRow === leaveId ? null : leaveId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  useEffect(() => {
    if (leavesData && leavesData.length > 0) {
      setData(leavesData);
    }
  }, [leavesData]);

  const tableHeaders =
    type === "active" ? activeTableHeaders : closedTableHeaders;

  const getStatusClass = (status) => {
    console.log(status);
    return status === "Approve" ? "status-approved" : "status-rejected";
  };

  return (
    <div>
      <div className="table">
        <div
          className={`table_header ${
            type === "active" ? "table_5" : "table_6"
          }`}
        >
          {tableHeaders.map((header) => (
            <div
              key={header.id}
              className={`table_header_cell ${
                sortConfig.key === header.id ? sortConfig.direction : ""
              }`}
              onClick={() => handleSort(header.id)}
            >
              {header.label}
            </div>
          ))}
          <div></div>
        </div>
        <div className="table_body">
          {data && data.length > 0 ? (
            data.map((leave) => (
              <div
                key={leave.id}
                className={`table_row ${
                  type === "active" ? "table_5" : "table_6"
                }`}
              >
                <div>{leave.employee_name}</div>
                <div>{leave.department_name}</div>
                <div>{leave.leave_type}</div>
                <div>{leave.leave_period}</div>
                {type === "closed" && (
                  <div
                    className={
                      leave.status === "Approve"
                        ? "status-approved"
                        : "status-rejected"
                    }
                  >
                    {leave.status}
                  </div>
                )}
                <button
                  className={`btn expand_btn `}
                  onClick={() => handleExpandRow(leave.id)}
                >
                  <Icon
                    icon="ri:arrow-drop-down-line"
                    className={`${expandedRow === leave.id ? "hide" : ""}`}
                  />
                </button>

                {expandedRow === leave.id && (
                  <div className="table_expanded_details table_expanded_details--column">
                    <div className="row">
                      {type === "closed" && (
                        <div>
                          <strong>Applied On:</strong>
                          {leave.applied_on}
                        </div>
                      )}
                      <div>
                        <strong>No. of days:</strong>
                        {leave.number_of_days}
                      </div>
                      <div>
                        <strong>Reason:</strong>
                        {leave.reason}
                      </div>
                    </div>
                    {type === "active" && (
                      <div className="row">
                        <div>
                          <strong>Applied On:</strong>
                          {leave.applied_on}
                        </div>
                        <div className="btn_group btn_group--end">
                          <button className="btn approve">Approve</button>
                          <button className="btn reject">Reject</button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no_data">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeavesTable;
