import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./JobTable.module.css";

function JobTable({ jobsData, onJobSelect }) {
  const [jobs, setJobs] = useState(jobsData);
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleExpandRow = (jobId) => {
    setExpandedRow(expandedRow === jobId ? null : jobId);
  };

  const handleSort = (key) => {
    console.log(jobs);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedJobs = [...jobs].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setJobs(sortedJobs);
  };

  // Sync the jobsData prop with the local jobs state
  useEffect(() => {
    if (jobsData && jobsData.length > 0) {
      setJobs(jobsData); // Update local state with the jobsData prop
    }
  }, [jobsData]); // This will run whenever jobsData changes

  return (
    <div>
      <div className="table">
        <div className={`table_header ${styles.table_header}`}>
          <div onClick={() => handleSort("job_number")}>Job Code ▾</div>
          <div onClick={() => handleSort("project_date")}>Job Date ▾</div>
          <div onClick={() => handleSort("building_type")}>Job Name ▾</div>
          <div onClick={() => handleSort("job_status")}>Job Status ▾</div>
          <div></div>
        </div>
        <div className="table_body">
          {jobs.map((job) => (
            <div key={job.id} className={`table_row ${styles.table_row}`}>
              <div>{job.job_number}</div>
              <div>{job.project_date}</div>
              <div>Job Name {job.id}</div>
              <div className="status">{job.job_status}</div>
              <button className="btn" onClick={() => handleExpandRow(job.id)}>
                {expandedRow === job.id ? (
                  <img src="/assets/images/icons/up-arrow.svg" alt="" />
                ) : (
                  <img src="/assets/images/icons/down-arrow.svg" alt="" />
                )}
              </button>
              {expandedRow === job.id && (
                <div
                  className={`table_expanded_details ${styles.expanded_details}`}
                >
                  <div>
                    <strong>Job Owner:</strong> {job.owner_name}
                  </div>
                  <div>
                    <strong>Job Value:</strong> {job.total_amount}
                  </div>
                  <div className="progress">
                    <svg width="48" height="48">
                      <circle
                        cx="24" /* Center of the circle */
                        cy="24"
                        r="19" /* Radius adjusted for the new thickness */
                        stroke="#448AFF99"
                        strokeWidth="10" /* Line thickness set to 10 */
                        fill="none"
                      />
                      <circle
                        cx="24"
                        cy="24"
                        r="19"
                        stroke="#000080"
                        strokeWidth="10"
                        fill="none"
                        strokeDasharray="100"
                        strokeDashoffset={100 - 10}
                      />
                    </svg>

                    {/* job.progress */}
                    <div className="progress_text">{10}%</div>
                  </div>
                  <Link
                    to={`/jobs/${job.id}?stage=${encodeURIComponent(
                      "project-details"
                    )}`}
                    className="ghost_btn"
                  >
                    View Details
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobTable;
