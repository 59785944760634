import { JobTable } from "../../components/accounts";
import styles from "./AccountsDashboard.module.css";

const staticJobData = [
  {
    id: 1,
    job_code: "J001",
    job_date: "01/01/2023",
    job_name: "Job 1",
    job_status: "Pending",
    job_owner: "John Doe",
    job_value: "₹ 50,000",
  },
  {
    id: 2,
    job_code: "J002",
    job_date: "02/01/2023",
    job_name: "Job 2",
    job_status: "Assigned",
    job_owner: "Jane Smith",
    job_value: "₹ 60,000",
  },
  {
    id: 3,
    job_code: "J003",
    job_date: "03/01/2023",
    job_name: "Job 3",
    job_status: "Pending",
    job_owner: "John Doe",
    job_value: "₹ 70,000",
  },
  {
    id: 4,
    job_code: "J004",
    job_date: "04/01/2023",
    job_name: "Job 4",
    job_status: "Assigned",
    job_owner: "Jane Smith",
    job_value: "₹ 80,000",
  },
  {
    id: 5,
    job_code: "J005",
    job_date: "05/01/2023",
    job_name: "Job 5",
    job_status: "On Hold",
    job_owner: "John Doe",
    job_value: "₹ 90,000",
  },
  {
    id: 6,
    job_code: "J006",
    job_date: "06/01/2023",
    job_name: "Job 6",
    job_status: "Pending",
    job_owner: "Jane Smith",
    job_value: "₹ 100,000",
  },
];

const AccountsDashboard = () => {
  return (
    <>
      <div className="btn_group btn_group--end">
        <button type="button" className="btn add_btn">
          + New Hiring
        </button>
        <button type="button" className="btn add_btn">
          + New Request
        </button>
        <button type="button" className="btn add_btn">
          + Leave Request
        </button>
      </div>
      <section className="section">
        <h2 className="section_title section_title--uppercase">My Progress</h2>
        <section className={styles.container}>
          <div className="section_title_wrapper">
            <h3 className="section_title--uppercase">Attendance</h3>
            <button className="btn cta_outline_btn">February '25</button>
          </div>
          <div className={styles.card_group}>
            <div className={styles.card}>
              <span className={styles.card_label}>Payable Account</span>
              <span className={styles.card_value}>₹ 1.5Cr</span>
            </div>
            <div className={styles.card}>
              <span className={styles.card_label}>Overdue</span>
              <span className={styles.card_value}>₹ 2.07Cr</span>
            </div>
            <div className={styles.card}>
              <span className={styles.card_label}>% Overdue</span>
              <span className={styles.card_value}>45%</span>
            </div>
            <div className={styles.card}>
              <span className={styles.card_label}>Cash on Hand</span>
              <span className={styles.card_value}>₹ 3.81Cr</span>
            </div>
          </div>
        </section>
      </section>
      <section className="section">
        <div className="section_title_wrapper">
          <h2 className="section_title section_title--uppercase">
            Job Updates
          </h2>
          <button className="btn cta_btn">+ Add New Job</button>
        </div>
      </section>
      <JobTable jobsData={staticJobData} />
    </>
  );
};

export default AccountsDashboard;
