import { useEffect, useState } from "react";
import { UserRoles } from "../lib/constants";
import { LettersCertificates as AdminLettersCertificates } from "./admin";

function LettersCertificatesPage() {
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("role"));
    setRole(userRole);
  }, []);

  if (role === UserRoles.ADMIN) return <AdminLettersCertificates />;

  return (
    <div>
      <h1>Letters & Certificates Page</h1>
    </div>
  );
}

export default LettersCertificatesPage;
