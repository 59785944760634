import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Import NavLink from react-router-dom
import { sidebarAccountsMenu, sidebarAdminMenu, sidebarMenu, UserRoles } from "../../lib/constants";
import { useAuth } from "../../services/AuthContext";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    const isLoggedOut = await logout();
    if (isLoggedOut) navigate("/login");
  };
  const [menu, setMenu] = useState(sidebarMenu);

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("role"));
    setMenu(
      userRole === UserRoles.ADMIN
        ? sidebarAdminMenu
        : userRole === UserRoles.ACCOUNTS
        ? sidebarAccountsMenu
        : sidebarMenu
    );
  }, []);

  return (
    <aside className={styles.sidebar}>
      <div className={styles.logo_wrapper}>
        <img src="/assets/images/Structualtants.svg" alt="Logo" className={styles.logo_img} />
        <div className={styles.logo_name}>
          <span className={styles.logo_bold}>Structualtants</span>
          <br />
          <span className={styles.logo_regular}>Designs LLP</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <nav>
        <ul className={styles.menu}>
          {menu.map((item, index) => (
            <li key={index}>
              <NavLink to={item.link} className={({ isActive }) => (isActive ? styles.active_link : styles.menu_link)}>
                <Icon icon={item.icon} className={styles.icon} />
                <span>{item.title}</span>
              </NavLink>
              {item.submenu && (
                <ul className={styles.submenu}>
                  {item.submenu.map((submenuItem, submenuIndex) => (
                    <li key={submenuIndex}>
                      <NavLink
                        to={submenuItem.link}
                        className={({ isActive }) => (isActive ? styles.active_link : styles.menu_link)}
                      >
                        <Icon icon={submenuItem.icon} className={styles.icon} />
                        <span>{submenuItem.title}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.logout_wrapper}>
        <button className={styles.logout} onClick={handleLogout}>
          <Icon icon="material-symbols:logout" className={styles.icon} />
          <span>Logout</span>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
