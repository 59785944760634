import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function LettersCertificates() {
  const letterList = [
    {
      id: "promotion_letter",
      title: "Promotion Letter",
    },
    {
      id: "spot_award_certificate",
      title: "Spot Award Certificate",
    },
    {
      id: "employee_of_the_month_certificate",
      title: "Employee of the Month Certificate",
    },
    {
      id: "employee_of_the_quarter_certificate",
      title: "Employee of the Quarter Certificate",
    },
    {
      id: "employee_of_the_year_certificate",
      title: "Employee of the Year Certificate",
    },
    {
      id: "noc_from_company",
      title: "NOC from the Company",
    },
    {
      id: "service_certificate",
      title: "Service Certificate",
    },
    {
      id: "salary_slip",
      title: "Salary Slip",
    },
  ];

  return (
    <>
      <div className="btn_group btn_group--end">
        <button className="btn cta_outline_btn">+ Compose</button>
      </div>
      <div className="page_title_wrapper">
        <h1 className="page_title">Letters & Certificates</h1>
        <div className="btn_group">
          <button className="btn cta_outline_btn">+ Add</button>
        </div>
      </div>
      <div className="column">
        {letterList.map((letter) => (
          <div className="letter_card row" key={letter.id}>
            <div className="letter_title">{letter.title}</div>
            <div className="btn_group btn_group--end btn_group--dense">
              <button className="btn ghost_btn">
                <Icon icon="lucide:view" />
                preview
              </button>
              <button className="btn ghost_btn">
                <Icon icon="ic:outline-edit" />
                edit
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="section section--row">
        <div className="column section--full">
          <div className="section_title">Edit Details</div>
          <div className="letter_card column section--full">
            <div className="card_title">Promotion Letter Format</div>
            <input type="text" className="input" placeholder="Employee name" />
            <input type="text" className="input" placeholder="Greetings" />
            <input
              type="date"
              name="date"
              id="date"
              className="input"
              placeholder="Date"
            />
            <div className="input_wrapper">
              <label htmlFor="subject">The subject</label>
              <input type="text" className="input" placeholder="Enter text" />
            </div>
            <div className="input_wrapper">
              <label htmlFor="body">The body</label>
              <textarea
                type="text"
                className="textarea"
                placeholder="Enter text"
              />
            </div>
            <div className="input_wrapper">
              <label htmlFor="signature">Signature</label>
              <textarea
                type="text"
                className="textarea"
                placeholder="Enter text"
              />
            </div>
            <div className="row section--full">
              <span className="label label-gray">Attach File</span>
              <div className="btn_group  btn_group--end btn_group--dense">
                <Link to="" className="ghost_btn">
                  preview
                </Link>
                <button type="button" className="btn cta_btn">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="column section--full">
          <div className="section_title">Preview</div>
          <div className="column letter_card">
            <div className="btn_group btn_group--end btn_group--dense">
              <button type="button" className="btn ghost_btn">
                <Icon icon="lucide:copy" />
                copy
              </button>
              <button type="button" className="btn ghost_btn">
                <Icon icon="lucide:save" />
                save as PDF
              </button>
            </div>
            <div className="column letter_preview">
              <div>[Company_Name]</div>
              <div>[Company_Address]</div>
              <div>[Current_Date]</div>
              <div>Promotion Letter</div>
              <div>Dear [Employee_Name],</div>
              <div>Congratulations!</div>
              <div>
                The Company is pleased to inform you that you have been promoted
                from [Old_Employee_designation] to [New_employee_designation] in
                [Region].
              </div>
              <div>
                Also, your monthly remuneration has been revised to
                [New_amount].
              </div>
              <div>It shall be effective from [Date].</div>
              <div>
                All the other terms and conditions of your appointment remain
                unchanged.
              </div>
              <div>
                We appreciate the efforts put in by you and expect that you
                would continue to take up new challenges with the same
                enthusiasm in the future as well.
              </div>
              <div>Sincerely,</div>
              <div>[HR Name]</div>
              <div>[HR Designation]</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LettersCertificates;
