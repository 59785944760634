import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./CreditsView.module.css";

const staticCreditData = {
  project_name: "Project A",
  company_name: "ABC Company",
  customer_name: "John Doe",
  status: "",
  plot_no: 23,
  due_date: "2023-01-01",
  amount: "₹ 50,000",
  late_days: 5,
  amount_type: [
    { id: 1, type: "Token" },
    { id: 2, type: "EMI" },
    { id: 3, type: "Rebate" },
    { id: 4, type: "Registration Charges" },
    { id: 5, type: "Development Charges" },
    { id: 6, type: "Interest" },
  ],
  payment_method: [
    { id: 1, method: "UPI" },
    { id: 2, method: "IMPS" },
    { id: 3, method: "RTOS" },
    { id: 4, method: "NEFT" },
    { id: 5, method: "Cheque" },
    { id: 6, method: "DD" },
    { id: 7, method: "Payment by Finance Co" },
  ],
  reference_number: [
    { id: 1, number: "123535" },
    { id: 2, number: "438742" },
    { id: 3, number: "59379" },
    { id: 4, number: "37493793" },
    { id: 5, number: "47394792" },
    { id: 6, number: "3792" },
    { id: 7, number: "47297" },
  ],
};

function CreditsView() {
  const [creditsData, setCreditsData] = useState(staticCreditData);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.data}>
          <div className={styles.row}>
            <div className={styles.label}>Project Name</div>
            <div className={styles.value}>{creditsData.project_name}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Company Name</div>
            <div className={styles.value}>{creditsData.company_name}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Customer Name</div>
            <div className={styles.value}>{creditsData.customer_name}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Status</div>
            <button className={`btn ${styles.approve}`}>Approve</button>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Plot No</div>
            <div className={styles.value}>{creditsData.plot_no}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Due Date</div>
            <div className={styles.value}>{creditsData.due_date}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Amount</div>
            <div className={styles.value}>{creditsData.amount}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Late Days</div>
            <div className={styles.value}>{creditsData.late_days}</div>
          </div>
          <div className={styles.row_start}>
            <div className={styles.label}>Amount Type</div>
            <div className={styles.value_column}>
              {creditsData.amount_type.map((type) => (
                <div className={styles.value}>{type.type}</div>
              ))}
            </div>
          </div>
          <div className={styles.row_start}>
            <div className={styles.label}>Payment Method</div>
            <div className={styles.value_column}>
              {creditsData.payment_method.map((method) => (
                <div className={styles.value}>{method.method}</div>
              ))}
            </div>
          </div>
          <div className={styles.row_start}>
            <div className={styles.label}>Reference Number</div>
            <div className={styles.value_column}>
              {creditsData.reference_number.map((number) => (
                <div className={styles.value}>{number.number}</div>
              ))}
            </div>
          </div>
          <div className={styles.row_start}>
            <div className={styles.label}>Attach Screenshot</div>
            <Link to="" className="ghost_btn">
              View
            </Link>
          </div>
        </div>
        <div className="btn_group btn_group--end">
          <button type="button" className="btn cta_outline_btn">
            Create Invoice
          </button>
        </div>
      </div>

      <div className={`${styles.container} column`}>
        <section className="section section--row">
          <section className={`section_inner_container section--full`}>
            <div className={styles.section_title}>Create Quotation</div>
            <div className="column letter_card section--full">
              <div className={styles.section_title}>Edit Details</div>
              <div className="input_group">
                <input type="text" className="input" placeholder="Field" />
                <input type="text" className="input" placeholder="Field" />
                <input type="text" className="input" placeholder="Field" />
              </div>
              <section className={`section--full ${styles.btn_group}`}>
                <Link to="#" className="ghost_btn">
                  Attach File
                </Link>
                <div className={`btn_group--end btn_group btn_group--dense`}>
                  <Link to="#" className="ghost_btn">
                    Preview
                  </Link>
                  <button type="submit" className="btn cta_outline_btn">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <section className="section_inner_container section--full">
            <div className={styles.section_title}>Preview</div>
            <div className="column letter_card">
              <div className="btn_group btn_group--end btn_group--dense">
                <button type="button" className="btn ghost_btn">
                  <Icon icon="lucide:copy" />
                  copy
                </button>
                <button type="button" className="btn ghost_btn">
                  <Icon icon="lucide:save" />
                  save as PDF
                </button>
              </div>
              <div className="column letter_preview">
                <p className="preview_text">[Company_Name]</p>
                <p className="preview_text">[Company_Address]</p>
                <p className="preview_text">[Current_Date]</p>
                <p className="preview_text">Promotion Letter</p>
                <p className="preview_text">Dear [Client_Name]</p>
                <p className="preview_text">“Quotation”</p>
                <p className="preview_text">Sincerely,</p>
                <p className="preview_text">[HR_Name]</p>
                <p className="preview_text">[HR_Designation]</p>
              </div>
            </div>
          </section>
        </section>
        <div className="btn_group btn_group--end">
          <button type="button" className="btn cta_btn">
            <Icon icon="majesticons:share-line" />
            Share Invoice
          </button>
        </div>
      </div>
    </>
  );
}

export default CreditsView;
