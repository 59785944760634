import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function OfferLetter() {
  return (
    <section className="section">
      <section className="section_title section_title--uppercase">
        Offer Letter
      </section>
      <div className="offer_letter_data">
        <div className="label">Offer Letter sent to candidate</div>
        <div className="metadata_container">
          <div className="row metadata">
            <div>
              <Icon icon="tabler:check" />
              Send to candidate
            </div>
            <div>dd/mm/yyyy hh:mm</div>
          </div>
          <div className="row metadata">
            <div>
              <Icon icon="tabler:check" />
              Approved by candidate
            </div>
            <div className="">dd/mm/yyyy hh:mm</div>
          </div>
        </div>
        <div className="btn_group btn_group--end btn_group--dense">
          <Link to="" className="ghost_btn">
            View
          </Link>
          <button className="btn cta_outline_btn">Re-upload</button>
        </div>
      </div>
    </section>
  );
}

export default OfferLetter;
