import { jwtDecode } from "jwt-decode";
import { createContext, useContext } from "react";
import { toast } from "react-toastify";
import useApiService from "./useApiService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { post, get } = useApiService();

  const requestOtp = async (mobile) => {
    try {
      const response = await post("/api/auth/otp/request", { mobile });
      if (!response.success) {
        throw new Error("Failed to send OTP");
      }
      toast.success(`OTP sent to ${mobile}`);
      return true;
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");
      return false;
    }
  };

  const verifyOtp = async ({ mobile, otp }) => {
    try {
      const response = await post("/api/auth/otp/verify", { mobile, otp });
      if (!response.success) {
        throw new Error("Failed to verify OTP");
      }
      const { token, user, role } = response;
      console.log(response);
      if (token) {
        localStorage.setItem("authToken", token);
        localStorage.setItem("userData", JSON.stringify(user));
        localStorage.setItem("role", JSON.stringify(role));
        toast.success("OTP verified successfully");
        return true;
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      toast.error("Failed to verify OTP. Please try again.");
      return false;
    }
  };

  const logout = async () => {
    try {
      const response = await post("/api/auth/logout");
      if (!response.success) throw new Error("Logout failed.");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userData");
      localStorage.removeItem("role");
      return true;
    } catch (error) {
      toast.error("Logout failed. Please try again.");
      return false;
    }
  };

  const checkAuthStatus = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) return false;

    try {
      const { exp } = jwtDecode(authToken);
      // Check if token is still valid locally
      if (Date.now() < exp * 1000) {
        return true; // Token is valid
      }
      const response = await get("/api/session-status");
      if (!response.success) {
        throw new Error("Session validation failed");
      }
      console.log(response);
      const { token, user } = response;
      if (token) {
        localStorage.setItem("authToken", token);
        localStorage.setItem("userData", JSON.stringify(user));
        localStorage.setItem("role", JSON.stringify(user.role));
        return true;
      }
      return false;
    } catch (error) {
      console.error("Session validation failed:", error);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{ requestOtp, verifyOtp, logout, checkAuthStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
