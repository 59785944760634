import { Icon } from "@iconify/react";
import { useState } from "react";
import styles from "./AccountsTable.module.css";

const staticDebitsData = [
  {
    id: 1,
    agent_name: "John Doe",
    agent_id: "12345",
    project_name: "Project A",
    commission: "₹ 5,00,000",
    payment_method: [
      { id: 1, method: "UPI" },
      { id: 2, method: "IMPS" },
      { id: 3, method: "RTOS" },
      { id: 4, method: "NEFT" },
    ],
    reference_number: "1234567890",
  },
  {
    id: 2,
    agent_name: "Jane Smith",
    agent_id: "67890",
    project_name: "Project B",
    commission: "₹ 1,00,000",
    payment_method: [
      { id: 1, method: "UPI" },
      { id: 2, method: "IMPS" },
      { id: 3, method: "RTOS" },
      { id: 4, method: "NEFT" },
    ],
    reference_number: "9876543210",
  },
  {
    id: 3,
    agent_name: "John Doe",
    agent_id: "12345",
    project_name: "Project C",
    commission: "₹ 2,00,000",
    payment_method: [
      { id: 1, method: "UPI" },
      { id: 2, method: "IMPS" },
      { id: 3, method: "RTOS" },
      { id: 4, method: "NEFT" },
    ],
    reference_number: "1234567890",
  },
  {
    id: 4,
    agent_name: "Jane Smith",
    agent_id: "67890",
    project_name: "Project D",
    commission: "₹ 3,00,000",
    payment_method: [
      { id: 1, method: "UPI" },
      { id: 2, method: "IMPS" },
      { id: 3, method: "RTOS" },
      { id: 4, method: "NEFT" },
    ],
    reference_number: "9876543210",
  },
];

function DebitsPage() {
  const [debitsData, setDebitsData] = useState(staticDebitsData);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...debitsData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setDebitsData(sortedData);
  };

  const debitsTableHeaders = [
    { id: "agent_name", label: "Agent Name" },
    { id: "agent_id", label: "Agent ID" },
    { id: "project_name", label: "Project Name" },
    { id: "commission", label: "Commission" },
    { id: "payment_method", label: "Method of Payment" },
    { id: "reference_number", label: "Reference Number" },
    { id: "action", label: "Attach Screenshot" },
  ];

  return (
    <>
      <h2 className="section_title">Debits</h2>
      <section className="section">
        <h3 className="section_title">Payment Made</h3>
        <div className="data_table data_table_7">
          <div className="data_table_header">
            {debitsTableHeaders.map((header) => (
              <div key={header.id} className="data_table_header_cell">
                {header.label}
                {header.id !== "action" && (
                  <button
                    type="button"
                    className="sort_btn"
                    onClick={() => handleSort(header.id)}
                  >
                    <Icon
                      icon="ri:arrow-drop-down-line"
                      className={`sort_icon ${
                        sortConfig.key === header.id &&
                        sortConfig.direction === "asc"
                          ? "asc"
                          : "desc"
                      }`}
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className={`data_table_body ${styles.data_table_body}`}>
            {debitsData.map((debit) => (
              <div
                key={debit.id}
                className={`data_table_row ${styles.table_row}`}
              >
                <div className="data_table_cell">{debit.agent_name}</div>
                <div className="data_table_cell">{debit.agent_id}</div>
                <div className="data_table_cell">{debit.project_name}</div>
                <div className="data_table_cell">{debit.commission}</div>
                <div className={`data_table_cell ${styles.table_cell}`}>
                  {debit.payment_method.map((method) => (
                    <div key={method.id}>{method.method}</div>
                  ))}
                </div>
                <div className="data_table_cell">{debit.reference_number}</div>
                <div className="data_table_cell">
                  <button className="btn ghost_btn">Upload</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DebitsPage;
