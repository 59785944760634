import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./JobView.module.css";

const JobView = ({ jobData }) => {
  const {
    id,
    job_name,
    job_number,
    job_status,
    project_name,
    building_type,
    number_of_floors,
    drafted_by,
    company_name,
    project_date,
    site_address,
    total_area,
    designed_by,
  } = jobData;

  const staticWorkData = {
    ...jobData,
    work_title: "Work Title",
    department: "Department",
    employee: "Employee",
    hours: "3",
    minutes: "30",
    priority: "Urgent",
    schedule: "Week 1, Feb '25",
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
    total_rate: "3000",
    tasks: [
      {
        id: 1,
        name: "Task 1",
        document: "Document 1",
        uploaded_on: "16/12/2024",
        note: "Lorem ipsum dolor sit amet.",
        revisions: [
          {
            id: 1,
            revision_number: 1,
            document: "Document 1",
            uploaded_on: "16/12/2024",
            note: "Lorem ipsum dolor sit amet.",
          },
          {
            id: 2,
            revision_number: 2,
            document: "Document 1 revised",
            uploaded_on: "16/12/2024",
            note: "Lorem ipsum dolor sit amet.",
          },
          {
            id: 3,
            revision_number: 3,
            document: "Document 1 revised",
            uploaded_on: "16/12/2024",
            note: "Lorem ipsum dolor sit amet.",
          },
        ],
      },
      {
        id: 2,
        name: "Task 2",
        document: "Document 2",
        uploaded_on: "16/12/2024",
        note: "Lorem ipsum dolor sit amet.",
      },
      {
        id: 3,
        name: "Task 3",
        document: "Document 3",
        uploaded_on: "16/12/2024",
        note: "Lorem ipsum dolor sit amet.",
      },
    ],
  };
  const [workData, setWorkData] = useState({ ...staticWorkData });

  const [newDocumentName, setNewDocumentName] = useState("");

  const handleDocumentUpload = () => {};

  const [newNote, setNewNote] = useState("");
  const handleAddNote = (e) => {
    setNewNote(e.target.value);
  };

  const [showTaskForm, setShowTaskForm] = useState(false);
  const [newTaskData, setNewTaskData] = useState({
    task_name: "",
    document: "",
    note: "",
  });

  const handleAddTask = (e) => {
    e.preventDefault();
    // handle submission, validation

    setShowTaskForm(false);
  };

  const handleTaskSubmit = () => {};

  const [taskList, setTaskList] = useState(workData.tasks);

  return (
    <>
      {/* Job Details */}
      <div className={styles.wrapper}>
        <div className={styles.section_header}>
          <div className="section_title">{job_name ?? `Job Name ${id}`}</div>
        </div>

        <section className="section">
          <section className="section section--row">
            <section className="section--full section_inner_container">
              <div className="row">
                <div className="label">Job number</div>
                <div className="value">{job_number}</div>
              </div>
              <div className="row">
                <div className="label">Project Name</div>
                <div className="value">{project_name ?? "Project Name 1"}</div>
              </div>
              <div className="row">
                <div className="label">Job status</div>
                <div className="value">
                  <select className="select" value={job_status} disabled>
                    <option value="Completed">Certification complete</option>
                    <option value="Not Started">Pending</option>
                    <option value="Ongoing">In Progress</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="label">Building type</div>
                <div className="value">
                  <select className="select" value={building_type} disabled>
                    <option value="Commercial">Commercial building</option>
                    <option value="Residential">Residential building</option>
                    <option value="Industrial">Industrial building</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="label">Drafted by</div>
                <div className="value">{drafted_by}</div>
              </div>
            </section>

            <div className="vertical-line"></div>
            <section className="section--full section_inner_container">
              <div className="row">
                <div className="label">No. of floors</div>
                <div className="value">{number_of_floors}</div>
              </div>
              <div className="row">
                <div className="label">Company name</div>
                <div className="value">{company_name}</div>
              </div>
              <div className="row">
                <div className="label">Project date</div>
                <div className="value">{project_date}</div>
              </div>
              <div className="row">
                <div className="label">Site address</div>
                <div className="value">{site_address}</div>
              </div>
              <div className="row">
                <div className="label">Total area</div>
                <div className="value">{total_area} Sq. ft.</div>
              </div>
              <div className="row">
                <div className="label">Designed by</div>
                <div className="value">{designed_by}</div>
              </div>
            </section>
          </section>
        </section>
      </div>
      {/* Assigned Jobs */}
      <section className={styles.wrapper}>
        <div className="btn_group btn_group--end">
          <button
            type="button"
            className={styles.cta_outline_btn}
            onClick={() => setShowTaskForm(true)}
          >
            + Add Task
          </button>
        </div>
        <section className={styles.details_wrapper}>
          <div className={styles.row}>
            <div className="label">Job Code</div>
            <div className={styles.value}>
              {workData.job_number ?? `# ${workData.id}`}
            </div>
          </div>
          <div className={styles.row}>
            <div className="label">Job Name</div>
            <div className={styles.value}>
              {workData.job_name ?? `Job ${workData.id}`}
            </div>
          </div>
          <div className={styles.row}>
            <div className="label">Work Priority</div>
            <div className={styles.value}>{workData.priority}</div>
          </div>
          <div className={styles.row}>
            <div className="label">Schedule</div>
            <div className={styles.value}>{workData.schedule}</div>
          </div>
          <div className={styles.row}>
            <div className="label">Work Title</div>
            <div className={styles.value}>{workData.work_title}</div>
          </div>
          <div className={styles.row}>
            <div className="label">Department</div>
            <div className={styles.value}>{workData.department}</div>
          </div>
          <div className={styles.row}>
            <div className="label">Employee Assigned</div>
            <div className={styles.value}>{workData.employee}</div>
          </div>
          <div className={styles.row}>
            <div className="label">Work Timeline</div>
            <div className={styles.value}>
              {workData.hours}:{workData.minutes} left
            </div>
          </div>
          <div className={styles.full_row}>
            <div className="label">CAD File</div>
            <div className={styles.upload_group}>
              <Link to="#" className="ghost_btn">
                view CAD file
              </Link>
              <button className={styles.upload_btn}>Reupload</button>
            </div>
          </div>
          <div className={styles.full_row}>
            <span className="label">Upload Documents</span>
            <div className={`${styles.input_group_full} ${styles.documents}`}>
              <input
                type="text"
                name="document_name"
                onChange={(e) => setNewDocumentName(e.target.value)}
                value={newDocumentName}
                placeholder="Enter document name"
                className={`input ${styles.input}`}
              />
              <button
                type="button"
                className={styles.upload_btn}
                onClick={handleDocumentUpload}
              >
                Upload
                <input type="file" name="document" hidden />
              </button>
            </div>
          </div>
          <div className={styles.full_row}>
            <div className="label">Note/Comment</div>
            <div className={styles.note}>{workData.note}</div>
          </div>
          <section className={styles.full_row}>
            <span className="label">Add Note</span>
            <textarea
              className={`textarea ${styles.textarea}`}
              name="note"
              placeholder="Enter note"
              onChange={handleAddNote}
              value={newNote}
              rows={1}
              required
            />
          </section>
        </section>
        {/* New Task Form */}
        {showTaskForm && (
          <form
            id="add_task"
            className={styles.wrapper}
            onSubmit={handleAddTask}
          >
            <div className="form_title">Add New Task</div>
            <input
              className={`input ${styles.input}`}
              name="task_name"
              placeholder="Enter Task Name"
              onChange={(e) =>
                setNewTaskData({ ...newTaskData, task_name: e.target.value })
              }
              value={newTaskData.task_name}
              required
            />
            <div className="row">
              <input
                type="text"
                name="document_name"
                onChange={(e) => setNewDocumentName(e.target.value)}
                value={newDocumentName}
                placeholder="Enter document name"
                className={`input ${styles.input}`}
              />
              <div className={styles.btn_group}>
                <Link to="#" className="ghost_btn">
                  preview
                </Link>
                <button
                  type="button"
                  className={styles.upload_btn}
                  onClick={handleDocumentUpload}
                >
                  Upload
                  <input type="file" name="document" hidden />
                </button>
              </div>
            </div>
            <textarea
              className={`textarea ${styles.textarea}`}
              name="note"
              placeholder="Enter note"
              onChange={(e) =>
                setNewTaskData({ ...newTaskData, note: e.target.value })
              }
              value={newTaskData.note}
              rows={1}
              required
            />
            <section className={styles.btn_group}>
              <button
                type="button"
                className={styles.cancel_btn}
                onClick={() => setShowTaskForm(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                form="add_task"
                className={styles.cta_outline_btn}
              >
                Save
              </button>
            </section>
          </form>
        )}
        {taskList.length > 0 &&
          taskList.map((task) => (
            <section key={task.id} className={styles.wrapper}>
              <div className="">{task.name}</div>
              <div className={styles.row}>
                <span className="label">{task.document}</span>
                <span className={styles.metadata}>
                  Uploaded on {task.uploaded_on}
                </span>
                <div className={styles.upload_group}>
                  <Link to="#" className="ghost_btn">
                    preview
                  </Link>
                  <button className={styles.upload_btn}>Reupload</button>
                </div>
              </div>
              <div className={styles.row}>
                <div className="label">Note/Comment</div>
                <div className={styles.note}>{workData.note}</div>
              </div>
              {task.revisions && task.revisions.length > 0 && (
                <div className={styles.revision_wrapper}>
                  {task.revisions.map((revision) => (
                    <div className={styles.revision} key={revision.id}>
                      <div className={styles.revision_title}>
                        Revision {revision.revision_number}
                      </div>
                      <div className={styles.revision_body}>
                        <div>{revision.document}</div>
                        <div className={styles.row}>
                          <span className="metadata">
                            Uploaded on {revision.uploaded_on}
                          </span>
                          <div className="btn_group btn_group--dense">
                            <Link to="" className="ghost_btn">
                              preview
                            </Link>
                            <button className={styles.upload_btn}>
                              Re-upload
                            </button>
                          </div>
                        </div>
                        <div className={styles.row}>
                          <span className="label">Note/Comment</span>
                          <div className={styles.note}>{revision.note}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.btn_group}>
                <button type="button" className={styles.cta_outline_btn}>
                  Revise
                </button>
                <button
                  type="button"
                  className={styles.cta_btn}
                  onClick={handleTaskSubmit}
                >
                  Submit Work
                </button>
              </div>
            </section>
          ))}
      </section>
    </>
  );
};
export default JobView;
