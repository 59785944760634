import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "./JobQuotation.module.css";

const JobQuotation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStage = searchParams.get("stage");
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  useEffect(() => {
    const isCurrentStage = currentStage === "send-quotation";
    setIsSectionVisible(isCurrentStage);
  }, [currentStage]);

  const handleSectionToggle = () => {
    setIsSectionVisible((prev) => !prev);
  };

  return (
    <section className={`section ${styles.section}`}>
      <div className="section_header">
        <div className="section_title">Quotation</div>
        <button onClick={handleSectionToggle} className="btn toggle_btn">
          <img
            src="/assets/images/icons/mingcute_down-line.svg"
            alt="Toggle"
            className={`toggle_icon ${isSectionVisible ? "" : "hide"}`}
          />
        </button>
      </div>
      {isSectionVisible ? (
        <>
          <section className="section section--row">
            <section className="section_inner_container section--full">
              <div className={styles.section_title}>Create Quotation</div>
              <form id="job_quotation_form" className={`form ${styles.form}`}>
                <div className={styles.form_title}>Edit Details</div>
                <div className="input_group">
                  <input type="text" className="input" placeholder="Field" />
                  <input type="text" className="input" placeholder="Field" />
                  <input type="text" className="input" placeholder="Field" />
                </div>
                <section className={styles.btn_group}>
                  <Link to="#" className="ghost_btn">
                    Attach File
                  </Link>
                  <div className={`btn_group--end ${styles.btn_group}`}>
                    <Link to="#" className="ghost_btn">
                      Preview
                    </Link>
                    <button type="submit" className="btn cta_outline_btn">
                      Save
                    </button>
                  </div>
                </section>
              </form>
            </section>
            <section className="section_inner_container section--full">
              <div className={styles.section_title}>Preview</div>
              <div className={styles.form_data_preview}>
                <p className="preview_text">[Company_Name]</p>
                <p className="preview_text">[Company_Address]</p>
                <p className="preview_text">[Current_Date]</p>
                <p className="preview_text">Promotion Letter</p>
                <p className="preview_text">Dear [Client_Name]</p>
                <p className="preview_text">“Quotation”</p>
                <p className="preview_text">Sincerely,</p>
                <p className="preview_text">[HR_Name]</p>
                <p className="preview_text">[HR_Designation]</p>
              </div>
            </section>
          </section>
          <div className="btn_group btn_group--end">
            <button type="button" className="btn cta_btn">
              <img
                src="/assets/images/icons/majesticons_share-line.svg"
                alt="Share"
              />
              Share Quotation
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </section>
  );
};
export default JobQuotation;
