import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { UserRoles } from "../lib/constants";
import { useApiService } from "../services";
import { JobView as AnalystJobView } from "./analyst";
import { JobView as EmployeeJobView } from "./employee";
import styles from "./JobView.module.css";

const JobView = () => {
  const [role, setRole] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPause, setIsPause] = useState(false);

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("role"));
    setRole(userRole);
  }, []);

  const [jobData, setJobData] = useState({});
  const { get } = useApiService();
  const { id } = useParams();

  const jobStages = [
    "project-details",
    "work-allotment",
    "send-quotation",
    "certificates",
  ];
  const currentStage = searchParams.get("stage");
  const currentStageIndex = jobStages.findIndex(
    (stage) => stage === currentStage
  );

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await get(`/api/job/${id}`);
        if (response.success && response.data) {
          setJobData(response.data);
        } else {
          throw new Error("Failed to fetch job data");
        }
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };
    fetchJobData();
  }, []);

  const handlePause = () => {
    setIsPause((prev) => !prev);
  };

  const handleComplete = () => {};

  return (
    <>
      {/* Job Overview */}
      <section className={styles.job_overview}>
        <div className="row">
          <div>{jobData.job_number}</div>
          <div>{jobData.project_date}</div>
          <div>{jobData.job_name ?? `Job Name ${id}`}</div>
          <strong className={styles.job_stage}>
            {currentStage.split("-").join(" ")}
          </strong>
        </div>
        <div className="row">
          <div>
            <strong>Job Owner:</strong> {jobData.owner_name}
          </div>
          <div>
            <strong>Job Value:</strong> ₹ {jobData.total_amount}
          </div>
          <div className="progress">
            <svg width="48" height="48">
              <circle
                cx="24" /* Center of the circle */
                cy="24"
                r="19" /* Radius adjusted for the new thickness */
                stroke="#448AFF99"
                strokeWidth="10" /* Line thickness set to 10 */
                fill="none"
              />
              <circle
                cx="24"
                cy="24"
                r="19"
                stroke="#000080"
                strokeWidth="10"
                fill="none"
                strokeDasharray="100"
                strokeDashoffset={100 - 10}
              />
            </svg>

            {/* progress */}
            <div className="progress_text">{10}%</div>
          </div>
          <div>02h:58m left</div>
          <Link to="" className="ghost_btn">
            View Details
          </Link>
        </div>
        {role === UserRoles.EMPLOYEE && (
          <div className="row">
            <div>09:00 - 13:00</div>
            <div>#{jobData.job_number}</div>
            <div className="controllers">
              <div className="controller">
                <button className="btn controller_btn" onClick={handlePause}>
                  <Icon
                    icon={
                      isPause
                        ? "material-symbols:pause"
                        : "material-symbols:play-arrow"
                    }
                    className="controller_icon"
                  />
                </button>
                <span>{isPause ? "Pause" : "Resume"}</span>
              </div>
              <div className="controller">
                <button className="btn controller_btn" onClick={handleComplete}>
                  <Icon
                    icon="material-symbols:stop"
                    className="controller_icon"
                  />
                </button>
                <span>Complete</span>
              </div>
            </div>
            <div className={styles.time_left}>
              <span>{isPause ? "Task Paused" : "Task Started"}</span>
              <span> 02:58:45 left</span>
            </div>
          </div>
        )}
      </section>

      {/* Progress Bar */}
      <section className={styles.progress_bar}>
        {jobStages.map((stage, index) => (
          <div
            key={stage}
            className={`${styles.step} ${
              index < currentStageIndex
                ? styles.step_completed
                : index === currentStageIndex
                ? styles.step_active
                : ""
            }`}
          >
            <div className={styles.bullet}></div>
            {/* links are added to the previous stages, current stage and the next stage, but the stages cannot be skipped */}
            {/* for example, if currentStageIndex is 0, link is added to 0 and 1 but not 2,3 */}
            {role === UserRoles.ANALYST &&
            (index <= currentStageIndex || index === currentStageIndex + 1) ? (
              <Link
                to={`/dashboard/jobs/${id}?stage=${encodeURIComponent(stage)}`}
                className={`${styles.label} ${styles.link}`}
              >
                {stage.split("-").join(" ")}
              </Link>
            ) : (
              <div className={styles.label}>{stage.split("-").join(" ")}</div>
            )}
          </div>
        ))}
      </section>
      {role === UserRoles.ANALYST ? <AnalystJobView jobData={jobData} /> : ""}
      {role === UserRoles.EMPLOYEE ? <EmployeeJobView jobData={jobData} /> : ""}
    </>
  );
};
export default JobView;
