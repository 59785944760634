import { useEffect, useState } from "react";
import { UserRoles } from "../lib/constants";
import { LeaveManagement as AdminLeaveManagement } from "./admin";

function AttendancePage() {
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("role"));
    setRole(userRole);
  }, []);

  if (role === UserRoles.ADMIN) return <AdminLeaveManagement />;

  return (
    <div>
      <h1>Leave Management Page</h1>
    </div>
  );
}

export default AttendancePage;
