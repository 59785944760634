import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

function VouchersTable({ vouchersData, type, status }) {
  const activeTableHeaders = [
    { id: "employee_name", label: "Employee Name" },
    { id: "department_name", label: "Department" },
    { id: "expense_type", label: "Expense Type" },
    { id: "amount", label: "Amount" },
  ];

  const closedTableHeaders = [
    { id: "employee_name", label: "Employee Name" },
    { id: "department_name", label: "Department" },
    { id: "expense_type", label: "Expense Type" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
  ];

  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState(vouchersData);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleExpandRow = (voucherId) => {
    setExpandedRow(expandedRow === voucherId ? null : voucherId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  useEffect(() => {
    if (vouchersData && vouchersData.length > 0) {
      setData(vouchersData);
    }
  }, [vouchersData]);

  const tableHeaders =
    type === "active" ? activeTableHeaders : closedTableHeaders;

  return (
    <div>
      <div className="table">
        <div
          className={`table_header ${
            type === "active" ? "table_5" : "table_6"
          }`}
        >
          {tableHeaders.map((header) => (
            <div
              key={header.id}
              className={`table_header_cell ${
                sortConfig.key === header.id ? sortConfig.direction : ""
              }`}
              onClick={() => handleSort(header.id)}
            >
              {header.label}
            </div>
          ))}
          <div></div>
        </div>
        <div className="table_body">
          {data && data.length > 0 ? (
            data.map((voucher) => (
              <div
                key={voucher.id}
                className={`table_row ${
                  type === "active" ? "table_5" : "table_6"
                }`}
              >
                <div>{voucher.employee_name}</div>
                <div>{voucher.department_name}</div>
                <div>{voucher.expense_type}</div>
                <div>₹ {voucher.amount}</div>
                {type === "closed" && (
                  <div
                    className={
                      voucher.status === "Approve"
                        ? "status-approved"
                        : "status-rejected"
                    }
                  >
                    {voucher.status}
                  </div>
                )}
                <button
                  className={`btn expand_btn `}
                  onClick={() => handleExpandRow(voucher.id)}
                >
                  <Icon
                    icon="ri:arrow-drop-down-line"
                    className={`${expandedRow === voucher.id ? "hide" : ""}`}
                  />
                </button>

                {expandedRow === voucher.id && (
                  <div className="table_expanded_details table_expanded_details--column">
                    {type === "active" && (
                      <div className="row">
                        <div>
                          <span className="label">Note: </span>
                          {voucher.note}
                        </div>
                        <div>
                          <button className="btn ghost_btn underline">
                            <Icon icon="mdi:eye-outline" />
                            view attachment
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div>
                        <strong className="label">Applied On:</strong>
                        {voucher.applied_on}
                      </div>
                      {type === "active" && (
                        <div className="btn_group btn_group--end">
                          <button className="btn approve">Approve</button>
                          <button className="btn reject">Reject</button>
                        </div>
                      )}
                      {type === "closed" && status === "rejected" && (
                        <div>
                          <span className="label">Reason for rejection: </span>
                          {voucher.rejection_reason}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no_data">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VouchersTable;
