import {
  AssignedJobs,
  JobAssignForm,
  JobCertificates,
  JobDetails,
  JobQuotation,
} from "../../components/Job";

const JobView = ({ jobData }) => {
  return (
    <>
      {/* Job Details */}
      <JobDetails jobData={jobData} />

      {/* Job Quotation */}
      <JobQuotation />

      {/* Job Assign */}
      <JobAssignForm jobData={jobData} />

      <AssignedJobs jobData={jobData} />

      {/* Job Certificates */}
      <JobCertificates />
    </>
  );
};

export default JobView;
