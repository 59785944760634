import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApiService from "../../services/useApiService";

const JobForm = ({ onSubmitSuccess, onCancel, isVisible, jobData }) => {
  const [formData, setFormData] = useState({
    job_number: "", // jobNumber
    job_status: "", // jobStatus
    building_type: "", // buildingType
    no_of_floors: "", // noOfFloors
    total_amount: "", // totalAmount
    drafted_by: "", // draftedBy
    project_date: "", // projectDate
    site_address: "", // siteAddress
    total_area: "", // totalArea
    area_unit: "sq-ft", // areaUnit
    rate: "", // rate
    designed_by: "", // designedBy
    owner_name: "", // ownerName
    owner_mobile: "", // ownerMobile
    owner_phone: "", // ownerPhone
    owner_email: "", // ownerEmail
    owner_office: "", // ownerOffice
    owner_sanction: "", // ownerSanction
    architect_name: "", // architectName
    architect_mobile: "", // architectMobile
    architect_phone: "", // architectPhone
    architect_email: "", // architectEmail
    architect_office: "", // architectOffice
    architect_sanction: "", // architectSanction
    client_name: "", // clientName
    client_mobile: "", // clientMobile
    client_phone: "", // clientPhone
    client_email: "", // clientEmail
    client_office: "", // clientOffice
    client_sanction: "", // clientSanction
    builder_name: "", // builderName
    builder_mobile: "", // builderMobile
    builder_phone: "", // builderPhone
    builder_email: "", // builderEmail
    builder_office: "", // builderOffice
    builder_sanction: "", // builderSanction
  });

  const { id } = useParams();

  const { get, post, put, loading, error } = useApiService();
  const [isEditMode, setIsEditMode] = useState(false);
  const [jobId, setJobId] = useState(null);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      setJobId(id); // Extract job ID from the URL
      if (jobData) setFormData(jobData);
      else fetchJobData(id);
    }

    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        onCancel();
      }
    };

    if (isVisible) {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const fetchJobData = async (jobId) => {
    try {
      const data = await get(`/api/job/${jobId}`);
      setFormData(data.data); // Populate the form with fetched data
    } catch (err) {
      console.error("Failed to fetch job data:", err);
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    const isValid = Object.values(formData).every((value) =>
      typeof value === "string"
        ? value.trim() !== ""
        : value !== null && value !== undefined
    );

    if (!isValid) {
      alert("Please fill in all required fields.");
      return;
    }

    // Check if phone and email are valid
    // const phonePattern = /^[0-9]{10}$/;
    // if (
    //   !phonePattern.test(formData.ownerMobile) ||
    //   !phonePattern.test(formData.ownerPhone) ||
    //   !phonePattern.test(formData.architectMobile) ||
    //   !phonePattern.test(formData.architectPhone) ||
    //   !phonePattern.test(formData.clientMobile) ||
    //   !phonePattern.test(formData.clientPhone) ||
    //   !phonePattern.test(formData.builderMobile) ||
    //   !phonePattern.test(formData.builderPhone)
    // ) {
    //   alert("Please enter valid 10-digit phone numbers.");
    //   return;
    // }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emailFields = [
      formData.owner_email,
      formData.architect_email,
      formData.client_email,
      formData.builder_email,
    ];

    const isInvalidEmail = emailFields.some(
      (email) => !emailPattern.test(email)
    );

    if (isInvalidEmail) {
      toast.error("Please enter valid email addresses.");
      // alert("Please enter valid email addresses.");
      return;
    }

    try {
      let response;
      if (isEditMode) {
        response = await put(`/api/job/${jobId}`, formData);
      } else {
        response = await post("/api/job/store", formData);
      }
      if (response.success) {
        toast.success("Form submitted successfully.");
        if (onSubmitSuccess) onSubmitSuccess(); // Callback for success (e.g., navigation)
      } else throw new Error("Failed to submit job details.");
    } catch (err) {
      toast.error(err);
      console.error("Failed to submit form:", err);
    }
  };

  const handleCancel = () => onCancel();

  return (
    <div className="modal-backdrop" onClick={handleCancel}>
      <div className="modal form_wrapper" onClick={(e) => e.stopPropagation()}>
        <header className="form_header">
          <img
            src="/assets/images/admin-panel-logo.svg"
            alt="Logo"
            className="form_logo"
          />
          <h2 className="form_title">
            {isEditMode ? "Edit Job" : "New Job Creation"}
          </h2>
        </header>
        <form className="form" onSubmit={handleSubmit} id="new-job-form">
          <section className="section section--row">
            <section className="input_group section--full">
              <div className="input_wrapper">
                <label htmlFor="job-number" className="label">
                  Job number
                </label>
                <input
                  type="text"
                  id="job_number"
                  value={formData.job_number}
                  onChange={handleChange}
                  placeholder="Enter job number"
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="job-status" className="label">
                  Job status
                </label>
                <select
                  id="job_status"
                  value={formData.job_status}
                  onChange={handleChange}
                  required
                  className="select"
                >
                  <option value="" disabled>
                    Select Job status
                  </option>
                  <option value="Not Started">Not Started</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="input_wrapper">
                <label htmlFor="building-type" className="label">
                  Building type
                </label>
                <select
                  id="building_type"
                  value={formData.building_type}
                  onChange={handleChange}
                  required
                  className="select"
                >
                  <option value="" disabled>
                    Please select
                  </option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                </select>
              </div>
              <div className="input_wrapper">
                <label htmlFor="no-of-floors" className="label">
                  No. of floors
                </label>
                <input
                  type="number"
                  id="no_of_floors"
                  value={formData.no_of_floors}
                  onChange={handleChange}
                  placeholder="Enter no. of floors"
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="total-amount" className="label">
                  Total amount (₹)
                </label>
                <input
                  type="text"
                  id="total_amount"
                  value={formData.total_amount}
                  onChange={handleChange}
                  placeholder="Enter total amount"
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="drafted-by" className="label">
                  Drafted by
                </label>
                <input
                  type="text"
                  id="drafted_by"
                  value={formData.drafted_by}
                  onChange={handleChange}
                  placeholder="Enter name"
                  required
                  className="input"
                />
              </div>
            </section>
            <div className="vertical-line"></div>
            <section className="input_group section--full">
              <div className="input_wrapper">
                <label htmlFor="project-date" className="label">
                  Project Date
                </label>
                <input
                  type="date"
                  id="project_date"
                  value={formData.project_date}
                  onChange={handleChange}
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="site-address" className="label">
                  Site address
                </label>
                <input
                  type="text"
                  id="site_address"
                  value={formData.site_address}
                  onChange={handleChange}
                  placeholder="Enter site address"
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="total-area" className="label">
                  Total area
                </label>
                <div className="input_with_suffix_wrapper">
                  <input
                    type="text"
                    id="total_area"
                    value={formData.total_area}
                    onChange={handleChange}
                    placeholder="Enter total area"
                    required
                    className="input input_with_suffix"
                  />
                  <select
                    className="select input_suffix"
                    id="area_unit"
                    value={formData.area_unit}
                    onChange={handleChange}
                    required
                  >
                    <option value="sq-ft">Sq. ft.</option>
                    <option value="sq-m">Sq. m.</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
              </div>
              <div className="input_wrapper">
                <label htmlFor="rate" className="label">
                  Rate
                </label>
                <input
                  type="text"
                  id="rate"
                  value={formData.rate}
                  onChange={handleChange}
                  placeholder="Enter rate"
                  required
                  className="input"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="designed-by" className="label">
                  Designed by
                </label>
                <input
                  type="text"
                  id="designed_by"
                  value={formData.designed_by}
                  onChange={handleChange}
                  placeholder="Enter name"
                  required
                  className="input"
                />
              </div>
            </section>
          </section>

          <section className="section section--row">
            <section className="input_group section--full">
              <div>Owner details</div>
              <section className="input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="owner_name"
                    value={formData.owner_name}
                    onChange={handleChange}
                    placeholder="Owner name"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="owner_mobile"
                    value={formData.owner_mobile}
                    onChange={handleChange}
                    placeholder="Mobile number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="owner_phone"
                    value={formData.owner_phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="email"
                    id="owner_email"
                    value={formData.owner_email}
                    onChange={handleChange}
                    placeholder="Email address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="owner_office"
                    value={formData.owner_office}
                    onChange={handleChange}
                    placeholder="Office address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="owner_sanction"
                    value={formData.owner_sanction}
                    onChange={handleChange}
                    placeholder="Sanction authority"
                    required
                    className="input"
                  />
                </div>
              </section>
            </section>
            <div className="vertical-line"></div>
            <section className="input_group section--full">
              <div>Architect details</div>
              <section className="input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="architect_name"
                    value={formData.architect_name}
                    onChange={handleChange}
                    placeholder="Architect name"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="architect_mobile"
                    value={formData.architect_mobile}
                    onChange={handleChange}
                    placeholder="Mobile number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="architect_phone"
                    value={formData.architect_phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="email"
                    id="architect_email"
                    value={formData.architect_email}
                    onChange={handleChange}
                    placeholder="Email address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="architect_office"
                    value={formData.architect_office}
                    onChange={handleChange}
                    placeholder="Office address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="architect_sanction"
                    value={formData.architect_sanction}
                    onChange={handleChange}
                    placeholder="Sanction authority"
                    required
                    className="input"
                  />
                </div>
              </section>
            </section>
          </section>

          <section className="section section--row">
            <section className="input_group section--full">
              <div>Client Details</div>
              <section className="input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                    placeholder="Client name"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="client_mobile"
                    value={formData.client_mobile}
                    onChange={handleChange}
                    placeholder="Mobile number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="client_phone"
                    value={formData.client_phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="email"
                    id="client_email"
                    value={formData.client_email}
                    onChange={handleChange}
                    placeholder="Email address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="client_office"
                    value={formData.client_office}
                    onChange={handleChange}
                    placeholder="Office address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="client_sanction"
                    value={formData.client_sanction}
                    onChange={handleChange}
                    placeholder="Sanction authority"
                    required
                    className="input"
                  />
                </div>
              </section>
            </section>
            <div className="vertical-line"></div>
            <section className="input_group section--full">
              <div>Developer/Builder details</div>
              <section className="input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="builder_name"
                    value={formData.builder_name}
                    onChange={handleChange}
                    placeholder="Developer/Builder name"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="builder_mobile"
                    value={formData.builder_mobile}
                    onChange={handleChange}
                    placeholder="Mobile number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="builder_phone"
                    value={formData.builder_phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="email"
                    id="builder_email"
                    value={formData.builder_email}
                    onChange={handleChange}
                    placeholder="Email address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="builder_office"
                    value={formData.builder_office}
                    onChange={handleChange}
                    placeholder="Office address"
                    required
                    className="input"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    id="builder_sanction"
                    value={formData.builder_sanction}
                    onChange={handleChange}
                    placeholder="Sanction authority"
                    required
                    className="input"
                  />
                </div>
              </section>
            </section>
          </section>
          <section className="btn_group btn_group--end">
            <button
              type="button"
              onClick={handleCancel}
              className="btn cancel_btn"
            >
              Cancel
            </button>
            <button type="submit" form="new-job-form" className="btn cta_btn">
              {isEditMode ? "Save Changes" : "Submit"}
            </button>
          </section>
        </form>
      </div>
    </div>
  );
};

export default JobForm;
