import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { JobForm, JobTable } from "../../components/Job";
import { useApiService } from "../../services";

const AnalystDashboard = () => {
  const [jobsData, setJobsData] = useState([]);
  const [isJobCreateFormOpen, setIsJobCreateFormOpen] = useState(false);
  const { get } = useApiService();

  const handleAddJob = () => {
    setIsJobCreateFormOpen(true);
  };

  const closeJobCreateForm = () => {
    setIsJobCreateFormOpen(false);
  };

  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        const data = await get("/api/job"); // Call the API
        if (data.success) {
          setJobsData(data.data); // Store jobs in state
        }
      } catch (error) {
        console.error("Error fetching jobs data:", error);
      }
    };
    fetchJobsData();
  }, [get]);

  return (
    <>
      <div className="btn_group btn_group--end">
        <button type="button" className="btn add_btn">
          + New Hiring
        </button>
        <button type="button" className="btn add_btn">
          + New Request
        </button>
        <button type="button" className="btn add_btn">
          + Leave Request
        </button>
      </div>

      <section className="section section--row">
        <section className="section--full">
          <header className="section_header">
            <h3 className="section_title--uppercase">
              Monthly Performance Graph
            </h3>
            <button className="btn ghost_btn">
              <Icon icon="ri:arrow-drop-down-line" />
              <img
                src="/assets/images/icons/ri_arrow-drop-down-line.svg"
                alt=""
              />
              February, 2025
            </button>
          </header>
          <img
            className="performance_graph"
            src="/assets/images/octicon_graph-16.svg"
            alt=""
          />
        </section>
        <section className="section--full">
          <header className="section_header">
            <h3 className="section_title--uppercase">Event Calendar</h3>
          </header>
          {/* <div style={{ width: "90%", height: "auto", overflow: "hidden" }}>
              <iframe
                src="https://calendar.google.com/calendar/embed?src=shubh.patil153@gmail.com&ctz=Asia/Kolkata"
                style={{ border: 0 }}
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                title="Google Calendar"
              ></iframe>
            </div> */}
        </section>
      </section>

      <section className="section">
        <header className="section_header">
          <h3 className="section_title--uppercase">My Jobs</h3>
          <button type="button" className="btn cta_btn" onClick={handleAddJob}>
            + Add New Job
          </button>
        </header>
        <JobTable jobsData={jobsData} />
      </section>

      {isJobCreateFormOpen
        ? createPortal(
            <JobForm
              onCancel={closeJobCreateForm}
              onSubmitSuccess={closeJobCreateForm}
              isVisible={isJobCreateFormOpen}
            />,
            document.body
          )
        : ""}
    </>
  );
};

export default AnalystDashboard;
