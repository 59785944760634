import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./EmployeeDashboard.module.css";

const staticEmployeeJobs = [
  {
    id: 1,
    job_start_time: "09:00",
    job_end_time: "13:00",
    job_duration: "04:00",
    job_code: "0123",
    time_Completed: "01:00",
    job_name: "Job 1",
    job_phase: "Project Details",
    job_priority: "High",
    scheduled_date: "2023-01-01",
    work_title: "Work Title 1",
    department_name: "Department 1",
    employee_name: "Employee 1",
    // cad_file: {name: '', url: ''}
    // pdf: {name: '', url: ''}
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est! Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est! Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
  },
  {
    id: 2,
    job_start_time: "14:00",
    job_end_time: "18:00",
    job_duration: "4:00",
    job_code: "0124",
    time_left: "",
    time_Completed: "0:30",
    job_name: "Job 2",
    job_phase: "Work Allotment",
    job_priority: "Medium",
    scheduled_date: "2023-01-02",
    work_title: "Work Title 2",
    department_name: "Department 2",
    employee_name: "Employee 2",
    // cad_file: {name: '', url: ''}
    // pdf: {name: '', url: ''}
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
  },
  {
    id: 3,
    job_start_time: "16:00",
    job_end_time: "18:30",
    job_duration: "02:30",
    job_code: "0125",
    time_left: "",
    time_Completed: "0:00",
    job_name: "Job 3",
    job_phase: "Work Allotment",
    job_priority: "Low",
    scheduled_date: "2023-01-03",
    work_title: "Work Title 3",
    department_name: "Department 3",
    employee_name: "Employee 3",
    // cad_file: {name: '', url: ''}
    // pdf: {name: '', url: ''}
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
  },
  {
    id: 4,
    job_start_time: "13:45",
    job_end_time: "16:00",
    job_duration: "02:15",
    job_code: "0126",
    time_left: "",
    time_Completed: "02:00",
    job_name: "Job 4",
    job_phase: "Work Allotment",
    job_priority: "Low",
    scheduled_date: "2023-01-04",
    work_title: "Work Title 4",
    department_name: "Department 4",
    employee_name: "Employee 4",
    // cad_file: {name: '', url: ''}
    // pdf: {name: '', url: ''}
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, est!",
  },
];

export default function EmployeeDashboard() {
  const [jobs, setJobs] = useState(staticEmployeeJobs);
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [timeLeft, setTimeLeft] = useState("");
  const [isPause, setIsPause] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);

  useEffect(() => {
    const calculateTimeLeft = (total_duration, time_Completed) => {
      const total = total_duration.split(":");
      const Completed = time_Completed.split(":");

      const totalTime = new Date();
      const CompletedTime = new Date();

      totalTime.setHours(
        total[0],
        total[1],
        total.length === 3 ? total[2] : 0,
        0
      );
      CompletedTime.setHours(
        Completed[0],
        Completed[1],
        Completed.length === 3 ? Completed[2] : 0,
        0
      );

      const difference = totalTime - CompletedTime; // difference in milliseconds
      const differenceInMinutes = difference / 1000 / 60;
      let hours = Math.floor(differenceInMinutes / 60);
      if (hours < 0) hours = 24 + hours;
      let minutes = Math.floor(differenceInMinutes % 60);
      if (minutes < 0) minutes = 60 + minutes;
      const timeLeft = `${hours}h:${minutes < 10 ? `0${minutes}` : minutes}m`;
      setTimeLeft(timeLeft);
    };
    if (expandedRow) {
      const selectedJob = jobs.find((job) => job.id === expandedRow);
      if (selectedJob) {
        calculateTimeLeft(selectedJob.job_duration, selectedJob.time_Completed);
      }
    }
  }, [expandedRow]);

  const handleSort = (key) => {
    console.log(jobs);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedJobs = [...jobs].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setJobs(sortedJobs);
  };

  const handleExpandRow = (jobId) => {
    setExpandedRow(expandedRow === jobId ? null : jobId);
  };

  const handlePause = (jobId) => {
    setCurrentJobId(jobId);
    setIsPause((prev) => !prev);
  };

  const handleComplete = (jobId) => {
    // to do
  };

  return (
    <>
      <section className="section">
        <h2 className="section_title section_title--uppercase">My Progess</h2>
        <section className="section section--row">
          <section className="section_inner_container section--full">
            <div className={styles.chart_wrapper}>
              <div className={styles.chart}>
                <div className={styles.chart_title}>
                  {/* to do: make dynamic */}
                  <span className={styles.count}>40</span> Projects
                </div>
                <Icon
                  icon="flat-color-icons:pie-chart"
                  className={styles.chart_icon}
                />
              </div>
              <div className={styles.chart_labels}>
                <div className={styles.chart_label}>
                  <div className={`${styles.swatch} ${styles.completed}`}></div>
                  <div>Completed</div>
                </div>
                <div className={styles.chart_label}>
                  <div
                    className={`${styles.swatch} ${styles.in_process}`}
                  ></div>
                  <div>In-Process</div>
                </div>
                <div className={styles.chart_label}>
                  <div className={`${styles.swatch} ${styles.on_hold}`}></div>
                  <div>On-Hold</div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_inner_container section--full">
            <section className="section_title_wrapper">
              <h3 className="section_title section_title--uppercase">
                Attendance
              </h3>
              <button className="btn cta_outline_btn">
                <span>February '25</span>
                <Icon icon="ri:arrow-drop-down-line" className={styles.icon} />
              </button>
            </section>
            <section className={styles.attendance_grid}>
              <div className={styles.attendance_card}>
                <span className={styles.card_label}>Working Hours</span>
                <span>9.5</span>
              </div>
              <div className={styles.attendance_card}>
                <span className={styles.card_label}>Overtime Hours</span>
                <span>06</span>
              </div>
              <div className={styles.attendance_card}>
                <span className={styles.card_label}>-ve Hours</span>
                <span>00</span>
              </div>
              <div className={styles.attendance_card}>
                <span className={styles.card_label}>Total Working Hours</span>
                <span>15</span>
              </div>
            </section>
          </section>
        </section>
      </section>
      <section className="section">
        <div className="section_title_wrapper">
          <h3 className="section_title section_title--uppercase">My Jobs</h3>
          <button className="btn cta_outline_btn">
            <span>February '25</span>
            <Icon icon="ri:arrow-drop-down-line" className={styles.icon} />
          </button>
        </div>
        <div className={`row ${styles.week_row}`}>
          <Icon icon="mingcute:left-line" className={styles.icon} />
          <div className="week_wrapper">
            <div className="week current">Week 1</div>
            <span className="dates">3rd-7th</span>
          </div>
          <div className="week_wrapper">
            <div className="week">Week 2</div>
            <span className="dates">10th-14th</span>
          </div>
          <div className="week_wrapper">
            <div className="week">Week 3</div>
            <span className="dates">17th-21st</span>
          </div>
          <div className="week_wrapper">
            <div className="week">Week 4</div>
            <span className="dates">24th-28th</span>
          </div>
          <Icon icon="mingcute:right-line" className={styles.icon} />
        </div>
      </section>
      <div className="table">
        <div className="table_header table_5">
          <div onClick={() => handleSort("time")}>Time ▾</div>
          <div onClick={() => handleSort("job_code")}>Job Code ▾</div>
          <div></div>
          <div onClick={() => handleSort("task_status")}>Task Status ▾</div>
          <div></div>
        </div>
        <div className={`table_body ${styles.table_body}`}>
          {jobs.map((job) => (
            <div key={job.id} className="table_row table_5">
              <div>
                {job.job_start_time} - {job.job_end_time}
              </div>
              <div>#{job.job_code}</div>
              <div className="controllers">
                <div className="controller">
                  <button
                    className="btn controller_btn"
                    onClick={() => handlePause(job.id)}
                    disabled={expandedRow !== job.id && currentJobId !== job.id}
                  >
                    <Icon
                      icon={
                        isPause &&
                        (expandedRow === job.id || currentJobId === job.id)
                          ? "material-symbols:pause"
                          : "material-symbols:play-arrow"
                      }
                      className="controller_icon"
                    />
                  </button>
                  <span>
                    {isPause &&
                    (expandedRow === job.id || currentJobId === job.id)
                      ? "Pause"
                      : "Resume"}
                  </span>
                </div>
                <div className="controller">
                  <button
                    className="btn controller_btn"
                    onClick={() => handleComplete(job.id)}
                  >
                    <Icon
                      icon="material-symbols:stop"
                      className="controller_icon"
                    />
                  </button>
                  <span>Complete</span>
                </div>
              </div>
              <div className={styles.status}>
                <span className={styles.bold}>{job.job_phase}</span>
                {expandedRow === job.id && <span>{timeLeft}</span>}
              </div>
              <button className="btn" onClick={() => handleExpandRow(job.id)}>
                {expandedRow === job.id ? (
                  <img src="/assets/images/icons/up-arrow.svg" alt="" />
                ) : (
                  <img src="/assets/images/icons/down-arrow.svg" alt="" />
                )}
              </button>
              {expandedRow === job.id && (
                <div className="table_expanded_details table_expanded_details--column">
                  <hr />
                  <div className={styles.row_wrapper}>
                    <div className="row">
                      <div className="label">Job Code</div>
                      <div className="value">#{job.job_code}</div>
                    </div>
                    <div className="vertical_divider"></div>
                    <div className="row">
                      <div className="label">Job Name</div>
                      <div className="value">{job.job_name}</div>
                    </div>
                  </div>
                  <div className={styles.row_wrapper}>
                    <div className="row">
                      <div className="label">Work Priority</div>
                      <div className="value">{job.job_priority}</div>
                    </div>
                    <div className="vertical_divider"></div>
                    <div className="row">
                      <div className="label">Schedule</div>
                      <div className="value">
                        {job.scheduled_date}, {job.job_start_time} -{" "}
                        {job.job_end_time}
                      </div>
                    </div>
                  </div>
                  <div className={styles.row_wrapper}>
                    <div className="row">
                      <div className="label">Work Title</div>
                      <div className="value">{job.work_title}</div>
                    </div>
                    <div className="vertical_divider"></div>
                    <div className="row">
                      <div className="label">Department</div>
                      <div className="value">{job.department_name}</div>
                    </div>
                  </div>
                  <div className={styles.row_wrapper}>
                    <div className="row">
                      <div className="label">Employee Assigned</div>
                      <div className="value">{job.employee_name}</div>
                    </div>
                    <div className="vertical_divider"></div>
                    <div className="row">
                      <div className="label">Work Timeline</div>
                      <div className="value">{timeLeft}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="label">CAD File</div>
                    <div className="btn_group">
                      <Link to="" className="ghost_btn">
                        view CAD File
                      </Link>
                      <button type="button" className="btn cta_outline_btn">
                        Re-upload
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="label">PDF File</div>
                    <div className="btn_group">
                      <Link to="" className="ghost_btn">
                        view PDF File
                      </Link>
                      <button type="button" className="btn cta_outline_btn">
                        Re-upload
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="label">Note</div>
                    <div className={`value ${styles.note}`}>{job.note}</div>
                  </div>
                  <div className="btn_group btn_group--end">
                    <Link
                      to={`/jobs/${job.id}?stage=${encodeURIComponent(
                        "work-allotment"
                      )}`}
                      className="ghost_btn"
                    >
                      View details
                    </Link>
                  </div>
                  <div className="row">
                    <div className="label">Add Note</div>
                    <textarea
                      className={`textarea ${styles.textarea}`}
                      placeholder="Enter text"
                    />
                  </div>
                  <div className="btn_group btn_group--end">
                    <button type="button" className="btn cta_btn">
                      Submit Work
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
