import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../services";
import styles from "./Login.module.css";

function Login() {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0); // Cooldown starts at 0 (enabled)
  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(true);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const { requestOtp, verifyOtp } = useAuth();

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    setIsSendOtpDisabled(value.length !== 10 || isNaN(value)); // Enable send OTP button if 10 digits are entered
  };

  const handleOTPChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOTPBackspace = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSendOtp = async () => {
    const otpRequestStatus = await requestOtp(mobile);
    setIsOtpSent(otpRequestStatus);
    setIsSendOtpDisabled(otpRequestStatus);
    setResendCooldown(30);
    // Start cooldown timer
    const timer = setInterval(() => {
      setResendCooldown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOTP = async () => {
    if (resendCooldown > 0) return;
    handleSendOtp();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");

    if (otpValue.length < 6) {
      toast.error("Please enter a valid OTP.");
      alert("Please enter a valid OTP.");
      return;
    }

    const isVerified = await verifyOtp({ mobile, otp: otpValue });
    if (isVerified) navigate("/");
  };

  const isLoginButtonEnabled = otp.every((digit) => digit !== "");

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles["section--left"]}>
          <h2 className={styles.form_title}>LOGIN</h2>
          <form
            id="login_form"
            className={styles.login_form_wrapper}
            onSubmit={handleSubmit}
          >
            <div className={styles.input_container}>
              <label htmlFor="mobile" className={styles.label}>
                Mobile Number
              </label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={handleMobileChange}
                disabled={isOtpSent}
                pattern="[0-9]{10}"
                className={styles.input}
              />
            </div>
            {!isOtpSent ? (
              <button
                type="button"
                className={styles.send_otp_button}
                onClick={handleSendOtp}
                disabled={isSendOtpDisabled}
              >
                Get OTP
              </button>
            ) : (
              <>
                <div className={styles.input_container}>
                  <label htmlFor="otp" className={styles.label}>
                    Enter OTP
                  </label>
                  <div className={styles.otp_container}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        pattern="[0-9]{1}"
                        maxLength={1}
                        className={styles.otp_box}
                        value={digit}
                        onChange={(e) => handleOTPChange(e.target.value, index)}
                        onKeyDown={(e) => handleOTPBackspace(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className={styles.resend_otp}
                    onClick={handleResendOTP}
                    disabled={resendCooldown > 0}
                  >
                    {resendCooldown > 0
                      ? `Resend in ${resendCooldown} seconds`
                      : "Resend"}
                  </button>
                </div>
                {isLoginButtonEnabled ? (
                  <button
                    type="submit"
                    form="login_form"
                    className={styles.login_button}
                    disabled={!isLoginButtonEnabled}
                  >
                    LOGIN
                  </button>
                ) : (
                  ""
                )}
              </>
            )}
          </form>
        </div>
        <div className={styles["section--right"]}>
          <div className={styles.logo_wrapper}>
            <img
              src="/assets/images/Structualtants.svg"
              alt="logo"
              className={styles.logo_img}
            />
            <div className={styles.logo}>
              <h3 className={styles.logo_name}>
                <span className={styles.logo_bold}>Structualtants</span>
                <br />
                <span className={styles.logo_regular}>Designs LLP</span>
              </h3>
              <p className={styles.logo_tagline}>We make it simple.</p>
            </div>
          </div>
          <div className="illustration">
            <img
              src="/assets/images/city_life_gnpr 1.svg"
              alt="Illustration"
              className={styles.illustration}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Login;
