import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import LeavesTable from "../../components/admin/LeavesTable";
import { useApiService } from "../../services";

function LeaveManagement() {
  const { get } = useApiService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLeaves, setSelectedLeaves] = useState(
    searchParams.get("leaves")
  ); // active or closed
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get("status")
  ); // for closed leaves, accepted or rejected

  const [leavesData, setLeavesData] = useState(null);

  useEffect(() => {
    const currentLeavesSelected = searchParams.get("leaves");
    if (!currentLeavesSelected) {
      setSearchParams({ leaves: "active" });
      setSelectedLeaves("active");
    }

    if (currentLeavesSelected && currentLeavesSelected === "closed") {
      const selectedStatus = searchParams.get("status");
      if (!selectedStatus) {
        setSearchParams({ status: "accepted", leaves: "closed" });
        setSelectedStatus("accepted");
      }
    }
  }, []);

  useEffect(() => {
    const getActiveLeaves = async () => {
      try {
        const response = await get("/api/admin/leaves/active");
        if (response.success && response.data) {
          setLeavesData(response.data);
        }
        throw new Error("Failed to fetch leaves data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch leaves data. Please try again.");
      }
    };
    const getAcceptedClosedLeaves = async () => {
      try {
        const response = await get("/api/admin/leaves/closed/accepted");
        if (response.success && response.data) {
          setLeavesData(response.data);
        }
        throw new Error("Failed to fetch leaves data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch leaves data. Please try again.");
      }
    };
    const getRejectedClosedLeaves = async () => {
      try {
        const response = await get("/api/admin/leaves/closed/rejected");
        if (response.success && response.data) {
          setLeavesData(response.data);
        }
        throw new Error("Failed to fetch leaves data");
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch leaves data. Please try again.");
      }
    };
    // if (selectedLeaves === "active") {
    //   getActiveLeaves();
    // } else if (selectedLeaves === "closed") {
    //   if (selectedStatus === "accepted") {
    //     getAcceptedClosedLeaves();
    //   } else {
    //     getRejectedClosedLeaves();
    //   }
    // }
  }, [selectedLeaves, selectedStatus]);

  const handleLeaveTabChange = (tab) => {
    setSelectedLeaves(tab);
    setSearchParams({ leaves: tab });
  };

  const handleStatusTabChange = (tab) => {
    setSelectedStatus(tab);
    searchParams.append("status", tab);
    setSearchParams({ status: tab, leaves: "closed" });
  };

  const staticActiveLeaves = [
    {
      id: 5,
      employee_name: "John Doe",
      department_name: "HR",
      leave_type: "Sick Leave",
      leave_period: "2023-08-01 to 2023-08-05",
      number_of_days: 5,
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
    {
      id: 6,
      employee_name: "Jane Smith",
      department_name: "Finance",
      leave_type: "Casual Leave",
      leave_period: "2023-08-10 to 2023-08-15",
      number_of_days: 3,
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
    {
      id: 7,
      employee_name: "Alice Johnson",
      department_name: "Marketing",
      leave_type: "Sick Leave",
      leave_period: "2023-08-20 to 2023-08-25",
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      number_of_days: 5,
      applied_on: "2023-08-10",
    },
    {
      id: 8,
      employee_name: "Bob Brown",
      department_name: "Sales",
      leave_type: "Casual Leave",
      leave_period: "2023-08-30 to 2023-09-05",
      number_of_days: 3,
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
  ];

  const staticAcceptedLeaves = [
    {
      id: 1,
      employee_name: "John Doe",
      department_name: "HR",
      leave_type: "Sick Leave",
      leave_period: "2023-08-01 to 2023-08-05",
      status: "Approve",
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      number_of_days: 5,
      applied_on: "2023-08-10",
    },
    {
      id: 2,
      employee_name: "Jane Smith",
      department_name: "Finance",
      leave_type: "Casual Leave",
      leave_period: "2023-08-10 to 2023-08-15",
      number_of_days: 3,
      status: "Approve",
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      applied_on: "2023-08-10",
    },
  ];

  const staticRejectedLeaves = [
    {
      id: 3,
      employee_name: "John Doe",
      department_name: "HR",
      leave_type: "Sick Leave",
      leave_period: "2023-08-01 to 2023-08-05",
      number_of_days: 5,
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      status: "Rejected",
      applied_on: "2023-08-10",
    },
    {
      id: 4,
      employee_name: "Jane Smith",
      department_name: "Finance",
      leave_type: "Casual Leave",
      leave_period: "2023-08-10 to 2023-08-15",
      number_of_days: 3,
      reason:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi iure asperiores.",
      status: "Rejected",
      applied_on: "2023-08-10",
    },
  ];

  return (
    <>
      <div className="page_title_wrapper">
        <h1 className="page_title">Leaves</h1>
        <div className="btn_group btn_group--end">
          <button className="btn cta_outline_btn">+ Add Leave</button>
        </div>
      </div>
      <div className="toggle_group">
        <section className="toggle">
          <button
            type="button"
            className={`toggle_item ${
              selectedLeaves === "active" ? "active" : ""
            }`}
            onClick={() => handleLeaveTabChange("active")}
          >
            Active
          </button>
          <button
            type="button"
            className={`toggle_item ${
              selectedLeaves === "closed" ? "active" : ""
            }`}
            onClick={() => handleLeaveTabChange("closed")}
          >
            Closed
          </button>
        </section>
        {selectedLeaves === "closed" && (
          <section className="toggle">
            <button
              type="button"
              className={`toggle_item ${
                selectedStatus === "accepted" ? "active" : ""
              }`}
              onClick={() => handleStatusTabChange("accepted")}
            >
              Accepted
            </button>
            <button
              type="button"
              className={`toggle_item ${
                selectedStatus === "rejected" ? "active" : ""
              }`}
              onClick={() => handleStatusTabChange("rejected")}
            >
              Rejected
            </button>
          </section>
        )}
      </div>
      <LeavesTable
        leavesData={
          leavesData
            ? leavesData
            : selectedLeaves === "active"
            ? staticActiveLeaves
            : selectedStatus === "accepted"
            ? staticAcceptedLeaves
            : staticRejectedLeaves
        }
        type={selectedLeaves}
        status={selectedStatus}
      />
    </>
  );
}

export default LeaveManagement;
