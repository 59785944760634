import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApiService } from "../../../services";

function OnboardingRound1({ candidateData }) {
  const { post } = useApiService();
  const [integratedReasoningScore, setIntegratedReasoningScore] = useState(0);
  const [integratedReasoningRemark, setIntegratedReasoningRemark] =
    useState("");
  const [quantitativeReasoningScore, setQuantitativeReasoningScore] =
    useState(0);
  const [quantitativeReasoningRemark, setQuantitativeReasoningRemark] =
    useState("");
  const [verbalReasoningScore, setVerbalReasoningScore] = useState(0);
  const [verbalReasoningRemark, setVerbalReasoningRemark] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [totalScoreRemark, setTotalScoreRemark] = useState("");

  useEffect(() => {
    const total_score =
      parseInt(integratedReasoningScore) +
      parseInt(quantitativeReasoningScore) +
      parseInt(verbalReasoningScore);
    setTotalScore(total_score);
  }, [
    integratedReasoningScore,
    quantitativeReasoningScore,
    verbalReasoningScore,
  ]);

  const [status, setStatus] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !integratedReasoningScore ||
      !quantitativeReasoningScore ||
      !verbalReasoningScore ||
      !totalScore
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const formData = {
      candidate_id: candidateData.id,
      round_1: {
        integrated_reasoning: integratedReasoningScore,
        integrated_reasoning_remark: integratedReasoningRemark,
        quantitative_reasoning: quantitativeReasoningScore,
        quantitative_reasoning_remark: quantitativeReasoningRemark,
        verbal_reasoning: verbalReasoningScore,
        verbal_reasoning_remark: verbalReasoningRemark,
        total_score: totalScore,
        total_score_remark: totalScoreRemark,
        status: status,
      },
    };

    console.log(formData);

    // try {
    //   const response = post("/api/admin/onboarding/round/1", formData);
    //   if (response.success) {
    //     toast.success("Round 1 submitted successfully.");
    //   } else {
    //     toast.error("Failed to submit round 1. Please try again.");
    //   }
    // } catch (error) {
    //   console.error("Failed to submit round 1:", error);
    //   toast.error("Failed to submit round 1. Please try again.");
    // }
  };

  return (
    <form id="onboarding-round-1" onSubmit={handleSubmit} className="section">
      <div className="section_title_wrapper">
        <h3 className="section_title section_title--uppercase">
          Round 1 - Aptitude
        </h3>
        <span>Candidate Test Score</span>
      </div>
      <div className="score_input_row">
        <label htmlFor="integrated_reasoning" className="label">
          Integrated Reasoning
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="integrated_reasoning"
            name="integrated_reasoning"
            value={integratedReasoningScore}
            onChange={(e) => setIntegratedReasoningScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="integrated_reasoning_remark"
          placeholder="Enter Remark"
          id="integrated_reasoning_remark"
          value={integratedReasoningRemark}
          onChange={(e) => setIntegratedReasoningRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="quantitative_reasoning" className="label">
          Quantitative Reasoning
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="quantitative_reasoning"
            name="quantitative_reasoning"
            value={quantitativeReasoningScore}
            onChange={(e) => setQuantitativeReasoningScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="quantitative_reasoning_remark"
          placeholder="Enter Remark"
          id="quantitative_reasoning_remark"
          value={quantitativeReasoningRemark}
          onChange={(e) => setQuantitativeReasoningRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="verbal_reasoning" className="label">
          Verbal Reasoning
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="verbal_reasoning"
            name="verbal_reasoning"
            value={verbalReasoningScore}
            onChange={(e) => setVerbalReasoningScore(e.target.value)}
            className="input score_input"
            min="0"
            max="20"
            required
          />
          <span>/20</span>
        </div>
        <input
          type="text"
          name="verbal_reasoning_remark"
          placeholder="Enter Remark"
          id="verbal_reasoning_remark"
          value={verbalReasoningRemark}
          onChange={(e) => setVerbalReasoningRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="score_input_row">
        <label htmlFor="total_score" className="label bold">
          Total Score
        </label>
        <div className="score_input_wrapper">
          <input
            type="number"
            id="total_score"
            name="total_score"
            value={totalScore}
            className="input score_input"
            min="0"
            max="60"
            disabled
            required
          />
          <span>/60</span>
        </div>
        <input
          type="text"
          name="total_score_remark"
          placeholder="Enter Remark"
          id="total_score_remark"
          value={totalScoreRemark}
          onChange={(e) => setTotalScoreRemark(e.target.value)}
          className="input remark_input"
          required
        />
      </div>
      <div className="btn_group btn_group--dense">
        <button
          type="submit"
          className="btn reject"
          onClick={() => setStatus("rejected")}
        >
          Reject
        </button>
        <button
          type="submit"
          className="btn approve"
          onClick={() => setStatus("approved")}
        >
          Approve
        </button>
      </div>
    </form>
  );
}

export default OnboardingRound1;
