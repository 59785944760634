import { useState } from "react";
import { toast } from "react-toastify";
import { useApiService } from "../../../services";

function OnboardingRound3({ candidateData }) {
  const { post } = useApiService();
  const [salaryExpectation, setSalaryExpecation] = useState("");
  const [salaryFinalized, setSalaryFinalized] = useState("");

  const [status, setStatus] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!salaryExpectation || !salaryFinalized) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const formData = {
      candidate_id: candidateData.id,
      round_3: {
        salary_expectation: salaryExpectation,
        salary_finalized: salaryFinalized,
        status: status,
      },
    };

    console.log(formData);

    // try {
    //   const response = post("/api/admin/onboarding/round/3", formData);
    //   if (response.success) {
    //     toast.success("Round 3 submitted successfully.");
    //   } else {
    //     toast.error("Failed to submit round 3. Please try again.");
    //   }
    // } catch (error) {
    //   console.error("Failed to submit round 3:", error);
    //   toast.error("Failed to submit round 3. Please try again.");
    // }
  };

  return (
    <form id="onboarding-round-3" onSubmit={handleSubmit} className="section">
      <div className="section_title_wrapper">
        <h3 className="section_title section_title--uppercase">Round 3 - HR</h3>
        <span>Candidate Test Score</span>
      </div>
      <div className="row">
        <label htmlFor="salary_expectation" className="label">
          Salary Expectation
        </label>
        <input
          type="text"
          name="salary_expectation"
          placeholder="Enter Salary Expectation"
          id="salary_expectation"
          value={salaryExpectation}
          onChange={(e) => setSalaryExpecation(e.target.value)}
          className="input salary_input"
          required
        />
      </div>
      <div className="row">
        <label htmlFor="salary_finalized" className="label">
          Salary Finalized
        </label>
        <div className="metadata">
          CAP: ₹ {candidateData.cap ? candidateData.cap : "₹₹₹₹₹"}
        </div>
        <input
          type="text"
          name="salary_finalized"
          placeholder="Enter Remark"
          id="salary_finalized"
          value={salaryFinalized}
          onChange={(e) => setSalaryFinalized(e.target.value)}
          className="input salary_input"
          required
        />
      </div>
      <div className="row start">
        <span className="label">Salary Structure</span>
        <table className="salary_table">
          <tbody>
            <tr>
              <td>Salary CAP</td>
              <td>₹ 125,000.00</td>
            </tr>
            <tr>
              <td>Salary After Negotiation</td>
              <td>₹ 120,000.00</td>
            </tr>
            <tr>
              <td>Salary Per Month </td>
              <td>₹ 10,000.00</td>
            </tr>
            <tr>
              <td>Professional Fees</td>
              <td>- ₹ 200.00</td>
            </tr>
            <tr>
              <td>TDS 10%</td>
              <td>- ₹ 10,000.00</td>
            </tr>
            <tr>
              <td>Salary in hand per month</td>
              <td>₹ 8,800.00</td>
            </tr>
            <tr>
              <td>Salary per hour</td>
              <td>₹ 58.82</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_group btn_group--dense">
        <button
          type="submit"
          className="btn reject"
          onClick={() => setStatus("rejected")}
        >
          Reject
        </button>
        <button
          type="submit"
          className="btn approve"
          onClick={() => setStatus("approved")}
        >
          Approve
        </button>
      </div>
    </form>
  );
}

export default OnboardingRound3;
